import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../../redux/hooks";
import { useLazySendEbayCodeQuery } from "../../../../../utils/api/pepite/pepite.api";
import { Color } from "../../../../../utils/theme";

export function checkifCodeExitsAndSendIt() {
	const [searchParams] = useSearchParams();
	const code = searchParams.get("code");
	const [sendCode] = useLazySendEbayCodeQuery();
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	const sendCodeAndCheckResponse = async () => {
		if (code) {
			const res = await sendCode({
				code: code,
				user: user?.slug,
			});
			if ("data" in res) {
				console.log("res ebay", res.data);
				Swal.fire({
					icon: "success",
					title: `Votre token Ebay a été renouvellé avec succès! `,
					text: "Vous pouvez maintenant diffuser sur cette marketplace",
					showConfirmButton: true,
					confirmButtonText: "Fermer",
					customClass: {
						confirmButton: "btn-swal-close mx-4",
						container: "validation__step",
						title: "swal__success__title",
					},
				}).then(() => navigate("/user/mon-stock"));
			} else if ("error" in res) {
				console.log("res ebay", res.error);
				const err = res.error as any;
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Une erreur a survenue lors de la diffusion`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	};

	useEffect(() => {
		sendCodeAndCheckResponse();
	}, [code]);
}
export function checkDiffusionStatus() {
	const { search } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (search?.includes("diffusion=401") || search?.includes("diffusion=400")) {
			Swal.fire({
				icon: "error",
				title: "Votre pépite n'a pas été diffusée !",
				text: "Veuillez mettre à jour vos accès avant de reprendre la diffusion",
				showConfirmButton: true,
				confirmButtonText: "Fermer",
				customClass: {
					confirmButton: "btn-swal-close mx-4",
					container: "validation__step",
					title: "swal__success__title",
				},
			}).then(() => navigate("/user/mon-stock"));
		} else if (search?.includes("diffusion=200") || search?.includes("diffusion=201")) {
			Swal.fire({
				icon: "success",
				title: "🎉 Pépite en ligne 🎉",
				text: `Votre pépite vient d’être mise en ligne sur les marketplaces.`,
				iconColor: Color.success,
				showConfirmButton: true,
				confirmButtonText: "Fermer",
				customClass: {
					confirmButton: "btn-swal-close mx-4",
					container: "validation__step",
					title: "swal__success__title",
				},
			}).then(() => navigate("/user/mon-stock"));
		}
	}, [search]);
	useEffect(() => {
		if (search?.includes("token_ebay=200") || search?.includes("token_etsy=200")) {
			Swal.fire({
				icon: "success",
				title: `Votre token ${
					search?.includes("token_ebay=200") ? "Ebay" : "Etsy"
				} a été renouvellé avec succès! `,
				text: "Vous pouvez maintenant diffuser sur cette marketplace",
				showConfirmButton: true,
				confirmButtonText: "Fermer",
				customClass: {
					confirmButton: "btn-swal-close mx-4",
					container: "validation__step",
					title: "swal__success__title",
				},
			}).then(() => navigate("/user/mon-stock"));
		} else if (
			search?.includes("token_ebay=401") ||
			search?.includes("token_ebay=400") ||
			search?.includes("token_etsy=401") ||
			search?.includes("token_etsy=400")
		) {
			Swal.fire({
				icon: "error",
				title: "Une erreur inconnue a survenue lors du renouvellement",
				iconColor: Color.red,
				showConfirmButton: true,
				confirmButtonText: "Fermer",
				customClass: {
					confirmButton: "btn-swal-close mx-4",
					container: "validation__step",
					title: "swal__success__title",
				},
			}).then(() => navigate("/user/mon-stock"));
		}
	}, [search]);
}

import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import {
	useDeleteMultipleCollectionMutation,
	useDeleteMultiplePepitesCollectionMutation,
	useGetCollectionBySellerQuery,
	useGetPepitesByCollectionQuery,
} from "../../../../../utils/api/collection/collection.api";
import { calculatePageCount } from "../../../../../utils/utils";
import { ICollection } from "../../../../../utils/api/collection/collection.type";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useFilterPepite } from "../../../admin/Pepites/Pepites";
import { AppLocalStorage } from "../../../../../utils/storage";

function useCollection() {
	const userSlug = useAppSelector((s) => s?.user?.user?.slug);
	const [page, setPage] = useState<number>(1);
	const [search, setSearch] = useState<string>();
	const [produitsId, setProduitsId] = useState<number[]>([]);

	const perPage = 10;
	const { data, isLoading } = useGetCollectionBySellerQuery({
		slug: userSlug,
		limit: perPage,
		page,
		nom: search,
	});

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	const getProduitsId = (id: number) => {
		let collection = data?.results?.find((item) => item?.id === id);
		if (collection) {
			let produitsId = collection?.produits?.map((item) => item?.id);
			setProduitsId([...produitsId]);
		}
	};

	const handleFilterCollection = (e: React.FormEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value);
	};

	return {
		data,
		isLoading,
		collections: data?.results,
		setPage,
		handlePageClick,
		perPage,
		pageCount,
		isShowPagination: data?.count && data?.count > perPage,
		getProduitsId,
		produitsId,
		setSearch,
		handleFilterCollection,
		search,
	};
}

export function usePepitesCollection(collection?: ICollection) {
	const [page, setPage] = useState<number>(1);
	const [search, setSearch] = useState<string>();
	const [produitsId, setProduitsId] = useState<number[]>([]);
	const { handleFilterPepiteByName, handleFilterPepiteByStatut, pepiteName, status } =
		useFilterPepite();

	const perPage = 10;
	const { data, isLoading } = useGetPepitesByCollectionQuery({
		slug: collection?.slug,
		limit: perPage,
		page,
		nom: pepiteName,
		en_ligne: status,
	});

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	// const getProduitsId = (id: number) => {
	// 	let collection = data?.results?.find((item) => item?.id === id);
	// 	if (collection) {
	// 		let produitsId = collection?.produits?.map((item) => item?.id);
	// 		setProduitsId([...produitsId]);
	// 	}
	// };

	const handleFilterCollection = (e: React.FormEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value);
	};

	return {
		data,
		isLoading,
		pepites: data?.results,
		setPage,
		handlePageClick,
		perPage,
		pageCount,
		isShowPagination: data?.count && data?.count > perPage,
		// getProduitsId,
		produitsId,
		setSearch,
		handleFilterCollection,
		search,
		handleFilterPepiteByName,
		handleFilterPepiteByStatut,
	};
}

export function useSelectMultiple(listCollections?: ICollection[]) {
	let selected = AppLocalStorage.getItem("collectionIds");
	const [arrayOfCollectionsId, setArrayOfCollectionsId] = useState<number[]>([]);
	const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
	const { user } = useAppSelector((s) => s.user);

	useEffect(() => {
		if (selected && arrayOfCollectionsId?.length < 1) {
			setArrayOfCollectionsId(selected as number[]);
		}
	}, [selected, arrayOfCollectionsId]);

	const handleSelectAll = (e: any) => {
		setIsCheckAll(!isCheckAll);
		if (listCollections?.length) {
			let selected = listCollections.map((item) => Number(item?.id));
			setArrayOfCollectionsId(selected);
			AppLocalStorage.setItem("collectionIds", selected);
		}
		if (isCheckAll) {
			setArrayOfCollectionsId([]);
			AppLocalStorage.removeItem("collectionIds");
		}
	};

	const onToggleCheckedItem = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.checked) {
			let selected = [...arrayOfCollectionsId, Number(e.currentTarget.value)];
			setArrayOfCollectionsId(selected);
			AppLocalStorage.setItem("collectionIds", selected);
		} else {
			let selected = arrayOfCollectionsId?.filter(
				(id) => Number(e.currentTarget.value) !== id
			);
			setArrayOfCollectionsId(selected);
			AppLocalStorage.setItem("collectionIds", selected);
		}
	};

	const reset = () => {
		setArrayOfCollectionsId([]);
		AppLocalStorage.removeItem("collectionIds");
	};

	return {
		onToggleCheckedItem,
		user,
		handleSelectAll,
		arrayOfCollectionsId,
		isShowBroadcastBtn: arrayOfCollectionsId?.length > 1,
		isSelectedProduct: arrayOfCollectionsId?.length > 0,
		reset,
	};
}

export default useCollection;

export function useDeleteMulttipleCollection(collections: number[], reset: () => void) {
	const [deleteMultiple, { isSuccess, isError, error }] = useDeleteMultipleCollectionMutation();
	const onDelete = () => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer toutes les collections sélectionnées ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				return deleteMultiple({ collections: collections });
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("1", { collections });
			}
		});
	};
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Collections supprimées avec succès !`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => reset());
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ?? `Une erreur de status ${err?.status} est survenue !`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}

export function useDeleteMulttiplePepitesToCollection(
	produits: number[],
	reset: () => void,
	collection?: ICollection
) {
	const [deleteMultiple, { isSuccess, isError, error }] =
		useDeleteMultiplePepitesCollectionMutation();
	const onDelete = () => {
		console.log("produits", { produits });

		Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer toutes les pépites sélectionnées ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				console.log({ produits });
				return deleteMultiple({ slug: collection?.slug, produits: produits });
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("1", { produits });
			}
		});
	};
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `les pépites ont été supprimées avec succès !`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => reset());
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ?? `Une erreur de status ${err?.status} est survenue !`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { CouleurFormData, ICouleur } from "../../../../../utils/api/couleur/couleur.type";
import { useAddOrUpdateCouleurMutation } from "../../../../../utils/api/couleur/couleur.api";

function useCrudCouleur(closeModal: () => void, item?: ICouleur) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("La Couleur"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<CouleurFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdate, { isLoading, isSuccess, error, isError }] = useAddOrUpdateCouleurMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item ? "Couleur modifiée avec succès !" : "Couleur ajoutée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				closeModal();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		if (item) {
			setValue("nom", item?.nom);
			setValue("valeur", item?.valeur);
		} else {
			setValue("nom", "");
			setValue("valeur", "");
		}
	}, [item]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: CouleurFormData) => {
		console.log(data);
		data["valeur"] = "1";
		addOrUpdate({ slug: item?.slug, data: data });
	};
	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
	};
}

export default useCrudCouleur;

import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";
import { ButtonSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";
import RequiredMessage from "../../../common/RequiredMessage";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import "./Sellers.css";
import { IBoutique } from "../../../../utils/api/boutique/boutique.type";
import UseEditInfoBoutique from "../../user/Profil/useProfilForm/UseEditInfoBoutique";
import useEditVendeur from "./useForm/useEditVendeur";
import { useVendeurFromLocation } from "../../../../utils/api/vendeur/vendeur.api";

const EditSeller = () => {
	const [item] = useVendeurFromLocation();
	console.log({ item });
	const { register, onSubmit, errors, isLoading } = useEditVendeur(item);

	const BreadRoutes = [
		{ name: "Vendeurs", path: "/admin/vendeurs" },
		{
			name: `${!item?.slug ? "Ajouter" : "Modifier"} un vendeur`,
			path: `/admin/vendeurs/${
				!item?.slug ? "ajouter-un-vendeur" : item?.slug + "/modifier"
			}`,
		},
	];

	return (
		<div>
			<h1>Vendeurs</h1>
			<Breadcrumb routes={BreadRoutes} />
			<AdminCard marginTop={false}>
				<form onSubmit={onSubmit} className="pt-4">
					<div className="fashion__card p-3 mb-4">
						<h2 className="add-seller-bloc-title">Informations personelles</h2>

						<RequiredMessage />
						<div className="row">
							<div className="col-md-6">
								<Input
									label="Nom*"
									type="text"
									id="nom"
									{...register("nom")}
									error={errors?.nom}
									defaultValue={item?.nom}
								/>
							</div>
							<div className="col-md-6">
								<Input
									label="Prénom*"
									type="text"
									id="prenom"
									{...register("prenom")}
									error={errors?.prenom}
									defaultValue={item?.prenom}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									label="Téléphone"
									type="tel"
									id="tel"
									{...register("telephone")}
									error={errors?.telephone}
									defaultValue={item?.telephone}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									label="Adresse mail"
									type="email"
									id="email"
									{...register("email")}
									error={errors?.email}
									defaultValue={item?.email}
								/>
							</div>
						</div>
						<div className="btn-container flex-end mt-3">
							<ButtonSubmit
								label="Enregistrer les informations"
								isLoading={isLoading}
							/>
						</div>
					</div>
				</form>
				{item && item?.boutique && (
					<div className="fashion__card p-3 mb-4">
						<InfoBoutiquesForm boutique={item.boutique[0]} />
					</div>
				)}
			</AdminCard>
		</div>
	);
};
function InfoBoutiquesForm({ boutique }: { boutique?: IBoutique }) {
	const { register, onSubmit, errors, isLoading } = UseEditInfoBoutique(false, boutique);
	return (
		<form onSubmit={onSubmit}>
			<div className="info_user_block  border-bottom mb-4">
				<h2 className="add-seller-bloc-title">Informations de ma boutique</h2>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Nom"
							defaultValue={boutique?.nom_boutique}
							type="text"
							id="nom_boutique"
							{...register("nom_boutique")}
							error={errors?.nom_boutique}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Numéro de SIREN"
							defaultValue={boutique?.numero_siret}
							type="text"
							id="siret"
							{...register("numero_siret")}
							error={errors?.numero_siret}
						/>
					</div>
				</div>
			</div>
			<div className="info_user_block">
				<h2 className="add-seller-bloc-title">Adresse de facturation</h2>
				<div className="row">
					<div className="group-info col-12 mb-3">
						<Input
							label="Adresse"
							defaultValue={boutique?.adresse}
							type="text"
							id="adresse"
							{...register("adresse")}
							error={errors?.adresse}
						/>
					</div>
				</div>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Ville"
							defaultValue={boutique?.ville}
							type="text"
							id="ville"
							{...register("ville")}
							error={errors?.ville}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Code postal"
							defaultValue={boutique?.code_postal}
							type="text"
							id="code_postal"
							{...register("code_postal")}
							error={errors?.code_postal}
						/>
					</div>
				</div>
				<div className="flex-end py-3">
					<ButtonSubmit label="Enregistrer" isLoading={isLoading} mode="Secondary" />
				</div>
			</div>
		</form>
	);
}
export default EditSeller;

import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { ButtonAdd } from "../../../common/Button";
import { InputFilter } from "../../../common/Input";
import SellersTable from "../../tables/admin/SellersTable/SellersTable";
import SellersTrashTable from "../../tables/admin/SellersTable/SellersTrashTable";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import Breadcrumb from "../../../common/Breadcrumb";
const BreadRoutes = [
	{ name: "Vendeurs", path: "/admin/vendeurs" },
	{
		name: "Corbeille",
		path: "/admin/corbeille",
	},
];
const SellersTrash = () => {
	const [search, setSearch] = useState<string>("");
	return (
		<div className="sellers__page">
			<h1>Vendeurs</h1>
			<Breadcrumb routes={BreadRoutes} />
			<AdminCard>
				{/* <div className="d-flex justify-content-between my-4">
					<div className="filter-container">
						<label htmlFor="" className="me-2">
							Filtrer
						</label>
						<InputFilter
							label="Nom du vendeur"
							onChange={(e) => setSearch(e.currentTarget.value)}
						/>
					</div>
				</div> */}
				<SellersTrashTable word={search} />
			</AdminCard>
		</div>
	);
};

export default SellersTrash;

import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FiChevronRight, FiChevronUp } from "react-icons/fi";
import {
	useDeleteCategoryMutation,
	useGetCategoryQuery,
} from "../../../../../utils/api/category/category.api";
import { ICategory } from "../../../../../utils/api/category/category.type";
import { useDelete } from "../../../../../utils/helpers";
import { Color } from "../../../../../utils/theme";
import { useModal } from "../../../../../utils/utils";
import { ButtonAdd, ButtonDelete, ButtonEdit } from "../../../../common/Button";
import { CustomPagination } from "../../../../common/CustomPagination";
import TableSkeleton from "../../../../common/TableSkeleton";
import AddCategoryModal from "../../../modals/admin/AddCategoryModal";
import SubCategoryTable from "../SubCategorieTable/SubCategoryTable";

function CategorieTable() {
	const { page, setPage, data, isLoading, item, setItem, perPage, setPerPage } = useCategories();

	const { isShowModal, toggle } = useModal();
	const openModal = () => {
		setItem(undefined);
		toggle();
	};
	const openEditModal = (item: ICategory) => {
		setItem(item);
		toggle();
	};
	const actionFormatter: any = (cell: string, row: ICategory) => {
		return (
			<div className="gap-3 d-flex">
				<ButtonEdit onClick={() => openEditModal(row)} />
				<DeleteCategory item={row} />
			</div>
		);
	};
	const columns = [
		{
			dataField: "nom",
			text: "Nom des catégories",
			style: { textAlign: "left" },
		},
		{
			dataField: "actions",
			text: "Actions",
			formatter: (cell: any, row: ICategory) => actionFormatter(cell, row),
		},
	];

	const expandRow = {
		renderer: (row: ICategory) => {
			return (
				<div className="sub-category-container">
					<div className="title-admin-container">
						<h4 style={{ fontSize: 16, marginBottom: 10 }}>Sous-catégories</h4>
					</div>
					<div>
						<SubCategoryTable category={row} />
					</div>
				</div>
			);
		},
		showExpandColumn: true,
		onlyOneExpanding: true,
		expandByColumnOnly: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: any }) => {
			if (isAnyExpands) {
				return <b></b>;
			}
			return <b></b>;
		},
		expandColumnRenderer: ({ expanded }: { expanded: any }) => {
			if (expanded) {
				return (
					<b>
						{" "}
						<FiChevronUp size={24} color={Color.primary} />
					</b>
				);
			}
			return (
				<b>
					<FiChevronRight size={24} color={Color.primary} />
				</b>
			);
		},
	};
	return (
		<>
			<div className="flex-end mb-5">
				<ButtonAdd label="Ajouter une catégorie" onClick={openModal} />
			</div>
			{isLoading && <TableSkeleton headers={["#", "Nom", "Actions"]} />}
			{!isLoading && (
				<BootstrapTable
					keyField="id"
					data={data?.results}
					columns={columns}
					bordered={true}
					condensed={false}
					responsive={true}
					expandRow={expandRow}
					noDataIndication={() => "Pas de résultats"}
					wrapperClasses="table-responsive admin-table"
				/>
			)}
			{data?.count && data?.count > perPage && (
				<CustomPagination
					nbPages={data?.count}
					page={page}
					onChange={(page, perPage) => {
						setPerPage(perPage);
						setPage(page);
					}}
					perPage={perPage}
				/>
			)}
			<AddCategoryModal item={item} isOpen={isShowModal} closeModal={toggle} />
		</>
	);
}

export function useCategories() {
	const [page, setPage] = useState(1);
	const [item, setItem] = useState<ICategory>();
	const [perPage, setPerPage] = useState(10);
	const { data, isLoading } = useGetCategoryQuery({
		page,
		limit: perPage,
	});

	return {
		page,
		setPage,
		data,
		isLoading,
		item,
		setItem,
		perPage,
		setPerPage,
	};
}

export function DeleteCategory({ item }: { item: ICategory }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteCategoryMutation();
	const onDelete = useDelete<ICategory>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette catégorie ?",
		successMessage: "Catégorie supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

export default CategorieTable;

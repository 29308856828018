import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { cleannerError, useLocationState } from "../../../../utils/utils";
import { useAppSelector } from "../../../../redux/hooks";
import { useAddOrUpdateAdminMutation } from "../../../../utils/api/admin/admin.api";
import { IAdminFormData } from "../../../../utils/api/admin/admin.type";
import { IUser, UserType } from "../../../../utils/api/user/user.type";
import { useLocation } from "react-router-dom";

function useAdminProfil() {
	const validationSchema = yup.object().shape({
		prenom: yup.string().required().label("Prénom"),
		nom: yup.string().required().label("Nom"),
		email: yup.string().email().required().label("Email"),
		telephone: yup.string().required().label("Téléphone"),
	});
	const [avatar, setAvatar] = useState<string | undefined>(undefined);
	const [user, setUser] = useState<IUser>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm<IAdminFormData>({
		resolver: yupResolver(validationSchema),
	});

	const { user: authUser } = useAppSelector((s) => s.user);
	const [editUser, { isLoading }] = useAddOrUpdateAdminMutation();
	const itemState = useLocationState<IUser>(undefined);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const location = useLocation();

	useEffect(() => {
		if (location?.pathname?.includes("profil")) {
			setUser(authUser);
		} else {
			setUser(itemState);
		}
	});

	useEffect(() => {
		if (user) {
			setValue("prenom", user?.nom);
			setValue("nom", user?.nom);
			setValue("telephone", user?.telephone);
			setValue("adresse", user?.adresse);
			setValue("email", user?.email);
		}
	}, [user]);

	const handleChangeAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			const image = e.currentTarget.files[0];
			setAvatar(URL.createObjectURL(image));
			const userData = new FormData();
			await userData.append("avatar", image);
			const res = await editUser({
				slug: user?.slug,
				user_type: UserType.admin,
				data: userData,
			});

			if ("data" in res) {
				Swal.fire({
					icon: "success",
					title: "Photo  de profil modifée avec succès !",

					iconColor: Color.success,
					showConfirmButton: false,
					timer: 2000,
				});
			}
			if ("error" in res) {
				const err = res.error as any;
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de la modification",
					showConfirmButton: false,
					timer: 3000,
				});
			}
		}
	};

	const onSubmit = async (data: IAdminFormData) => {
		const res = await editUser({ slug: user?.slug, user_type: UserType.admin, data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Informations modifiées avec succès !",

				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			});
		} else if ("error" in res) {
			const { error } = res.error as any;
			Swal.fire({
				icon: "error",
				title: error?.data?.message
					? error?.data?.message
					: "Une erreur a survenue lors de la modification",
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		user,
		handleChangeAvatar,
		avatar,
	};
}

export default useAdminProfil;

import { Color } from "../../../../utils/theme";
import { Modal } from "reactstrap";
import ShopifyProductItemCard from "./ShopifyProductItemCard";
import { AlertInfo } from "../../../common/Alert";
import { PepiteCardSkeleton } from "./PepiteCard";
import { useImportShopifyProducts } from "./useImportShopifyProducts";
import { BtnPrimary, CloseModalButton } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import Pagination from "../../../common/Pagination";
import { useShopifyProducts } from "./useStock";
import { data } from "jquery";

type Props = { isOpen: boolean; closeModal: () => void };

const ModalDowloadShopifyProducts: React.FC<Props> = ({ isOpen, closeModal }) => {
	const {
		products,
		onFilterProductsByTitle,
		pageCount,
		isShowPagination,
		isLoading,
		handlePageClick,
	} = useShopifyProducts(isOpen);

	const {
		onSubmit,
		onToggleCheckedItem,
		isNotCheckedItem,
		isLoadImport,
		handleSelectAll,
		arrayOfProductsId,
		reset,
		isCheckAll,
	} = useImportShopifyProducts(closeModal, products);

	return (
		<>
			<Modal isOpen={isOpen} size="lg">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<form onSubmit={onSubmit}>
							<div className="mb-5">
								<div className="d-flex justify-content-between align-items-center mb-4">
									<h4>
										Liste des produits (
										<span style={{ fontWeight: 500 }}>
											{arrayOfProductsId.length}
										</span>{" "}
										sélectionnés)
									</h4>
									<CloseModalButton
										onClick={() => {
											closeModal();
											reset();
										}}
									/>
								</div>
								<div className="d-flex justify-content-between align-items-center gap-2 mb-3">
									<div className="form-group">
										<label htmlFor="search">Rechercher</label>
										<input
											type="search"
											className="form-control form-custom-control"
											onChange={onFilterProductsByTitle}
											id="search"
											placeholder="Rechercher par nom"
										/>
									</div>
									<div className="form-group">
										<label htmlFor="select-all">Tout sélectionner</label>
										<input
											type="checkbox"
											className="form-check-input fas__check ms-2"
											style={{ cursor: "pointer" }}
											onChange={handleSelectAll}
											id="select-all"
											checked={isCheckAll}
											disabled={arrayOfProductsId.length === 10}
										/>
									</div>
								</div>
								<div className="pepites__cards__container">
									{!isLoading ? (
										products && products?.length > 0 ? (
											<>
												{products?.map((item, i) => (
													<ShopifyProductItemCard
														key={item?.id + 1}
														product={item}
														onToggleCheckedItem={onToggleCheckedItem}
														arrayOfProductsId={arrayOfProductsId}
													/>
												))}
												{isShowPagination ? (
													<div className="flex-c">
														<Pagination
															title="Page"
															handlePageClick={handlePageClick}
															pageCount={pageCount}
														/>
													</div>
												) : null}
											</>
										) : (
											<AlertInfo message="Aucune pépite trouvée" />
										)
									) : (
										<>
											{[...Array(5)]?.map((item, i) => (
												<PepiteCardSkeleton key={i} />
											))}
										</>
									)}
								</div>
								{isNotCheckedItem && (
									<FormError
										error={"Veuillez sélectionner au moins un produit"}
									/>
								)}
							</div>
							<div className="flex-end mt-5 gap-2">
								<button
									type="button"
									className="fashion__btn"
									style={{ color: Color.primary }}
									onClick={closeModal}
								>
									Fermer
								</button>
								<BtnPrimary
									label="Importer"
									mode="Secondary"
									isLoading={isLoadImport}
								/>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ModalDowloadShopifyProducts;

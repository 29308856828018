import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from "../../../../common/AdminPagination";
import StockPepitesTableSkeleton from "./StockPepitesTableSkeleton";
import { useParams } from "react-router-dom";
import { useGetPepitesByUserQuery } from "../../../../../utils/api/pepite/pepite.api";
import { IPepite } from "../../../../../utils/api/pepite/pepite.type";
import { getImage } from "../../../../../utils/utils";
import ebay from "../../../../../assets/images/Diffusion/Ebay.png";
import Etsy from "../../../../../assets/images/Diffusion/etsy.png";

function StockPepitesTable() {
	const { slug } = useParams();
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const { data, isLoading } = useGetPepitesByUserQuery({
		page,
		limit: perPage,
		slug,
	});

	console.log({ data });

	const plateformFormatter = (cell: any, row: IPepite) => {
		return (
			<div className="flex-m gap-2">
				{row?.diffusions?.length > 0 && (
					<div className="img__container">
						{row?.diffusions?.map((item) => {
							if (item?.marketplace === "etsy") {
								return (
									<img
										src={Etsy}
										alt="etsy"
										width="50px"
										key={item?.marketplace}
									/>
								);
							} else if (item?.marketplace === "ebay") {
								return (
									<img
										src={ebay}
										alt="ebay"
										width="50px"
										className="me-3"
										key={item?.marketplace}
									/>
								);
							}
							return null;
						})}
					</div>
				)}
			</div>
		);
	};

	const statutFormatter = (cell: any, row: IPepite) => {
		return <span>{!row?.en_ligne ? "Ébauche" : "En ligne"}</span>;
	};

	const nameFormatter: any = (cell: any, row: IPepite) => {
		return (
			<div className="flex-m gap-2">
				<div className="img-container">
					<img
						src={getImage(
							row?.photo_principale
								? getImage(row?.photo_principale)
								: getImage(row?.images[0]?.picture)
						)}
						alt={cell}
						style={{ height: 26, width: 26 }}
					/>
				</div>
				<span>{cell}</span>
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Nom",
			formatter: (cell: any, row: IPepite) => nameFormatter(cell, row),
		},
		{
			dataField: "id",
			text: "Diffusé",
			formatter: (cell: any, row: any) => plateformFormatter(cell, row),
		},
		{
			dataField: "statut",
			text: "Statut",
			formatter: (cell: any, row: IPepite) => statutFormatter(cell, row),
		},
	];

	return (
		<>
			<h4 className="total-stock">
				<span className="fw-500">Stocks</span>
				<span style={{ fontWeight: 200, fontSize: 14 }}>({data?.count} produits)</span>
			</h4>
			{isLoading && <StockPepitesTableSkeleton />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={false}
						condensed={false}
						responsive
						striped={false}
						wrapperClasses="table-responsive admin-table"
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
		</>
	);
}

export default StockPepitesTable;

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-end">
			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}

type ENV = "dev" | "prod" | "demo";

export const currentEnv: ENV = "demo";

const env: ENV = currentEnv;

export const Env = env;

const API_DEV_URL = "https://api-fashion-trustee.withvolkeno.com";
const API_PROD_URL = "https://api.tftsolution.io";
const API_DEMO_URL = "https://api-stagging.tftsolution.io";

export const APP_URL = "https://api-fashion-trustee.withvolkeno.com";

function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	return API_DEV_URL;
}
export const ApiBaseUrl = processApiUrl();

const EBAY_SANDBOX_URL =
	"https://auth.sandbox.ebay.com/oauth2/authorize?client_id=volkenos-TFTSolut-SBX-e5ea32768-7825fb1c&response_type=code&redirect_uri=volkeno-shop-volkenos-TFTSol-hoxbme&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/buy.order.readonly https://api.ebay.com/oauth/api_scope/buy.guest.order https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.marketplace.insights.readonly https://api.ebay.com/oauth/api_scope/commerce.catalog.readonly https://api.ebay.com/oauth/api_scope/buy.shopping.cart https://api.ebay.com/oauth/api_scope/buy.offer.auction https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.email.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.phone.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.address.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.name.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.status.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.item.draft https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/sell.item https://api.ebay.com/oauth/api_scope/sell.reputation https://api.ebay.com/oauth/api_scope/sell.reputation.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly";

const EBAY_PROD_URL =
	"https://auth.ebay.com/oauth2/authorize?client_id=volkenos-TFTSolut-PRD-da2742ab5-02af0290&response_type=code&redirect_uri=volkeno-shop-volkenos-TFTSol-qgjedbdkx&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly";

export const EBAY_REDIRECT_URL = EBAY_PROD_URL;

export const ETSY_REDIRECT_URL =
	`https://www.etsy.com/oauth/connect?response_type=code&redirect_uri=${ApiBaseUrl}/api/etsy/user_accces_token/&scope=transactions_r%20transactions_w%20listings_w%20listings_r%20shops_r%20shops_w%20profile_r%20profile_w%20listings_d&client_id=hwk0z527fjhfg8w12h0bw217&state=superstate&code_challenge=DSWlW2Abh-cf8CeLL8-g3hQ2WQyYdKyiu83u_s7nRhI&code_challenge_method=S256`;
//https://api-stagging.tftsolution.io/
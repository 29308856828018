import { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import {
	useCollectionFromLocation,
	useRemoveProductFromCollectionMutation,
} from "../../../../utils/api/collection/collection.api";
import { ICollection } from "../../../../utils/api/collection/collection.type";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { formatPlurial } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import { BtnBack, BtnPrimary } from "../../../common/Button";
import DiffuserModalPepite from "../Diffuser/DiffuserModalPepite";
import PepiteCard, { PepiteCardSkeleton } from "../Stock/PepiteCard";
import { useCheckTokenStatus, useGetPepite } from "../Stock/useStock";
import { useDiffuseMassProducts } from "../Stock/useDiffuseMassProducts";
import PanelFilter from "../../../common/PanelFilter";
import { BsSquareFill } from "react-icons/bs";
import { useDeleteMulttiplePepitesToCollection, usePepitesCollection } from "./hooks/useCollection";
import Pagination from "../../../common/Pagination";
import ModalDiffusionMassProducts from "../Stock/ModalDiffusionMassProducts";

function DeleteMultiplePepite({
	produits,
	reset,
	collection,
}: {
	produits: number[];
	reset: () => void;
	collection?: ICollection;
}) {
	const onDelete = useDeleteMulttiplePepitesToCollection(produits, reset, collection);
	return (
		<button onClick={onDelete} className="fashion__btn btn-delete-all">
			Supprimer
		</button>
	);
}

const DetailCollection = () => {
	const { currentCollection } = useCollectionFromLocation();

	const {
		pepites,
		isLoading,
		data,
		pageCount,
		handlePageClick,
		isShowPagination,
		handleFilterPepiteByName,
		handleFilterPepiteByStatut,
	} = usePepitesCollection(currentCollection);

	const { item, getCurrentPepite } = useGetPepite();
	const { tokenStatus, onCheckTokenStatus, checkMarketPlaceTokenValidity } =
		useCheckTokenStatus();

	const {
		onToggleCheckedItem,
		handleSelectAll,
		arrayOfProductsId,
		isShowBroadcastBtn,
		reset,
		isSelectedProduct,
	} = useDiffuseMassProducts(pepites);

	console.log({ arrayOfProductsId });

	return (
		<div className="collection__page">
			<div className="d-flex flex-column flex-md-row justify-content-md-between gap-3">
				<div className="d-flex flex-md-row align-items-center mt-3">
					<BtnBack label="Revenir sur mes collections/ " />
					<h3>{currentCollection?.libelle}</h3>
				</div>
				{isShowBroadcastBtn && (
					<BtnPrimary
						label="Diffuser"
						className="fashion__btn btn__add px-3 py-2"
						data-bs-toggle="modal"
						data-bs-target="#ModalDiffusionMassProducts"
						onClick={() => checkMarketPlaceTokenValidity()}
					/>
				)}
			</div>
			<PanelFilter
				filterPepiteByName={handleFilterPepiteByName}
				filterPepiteByStatut={handleFilterPepiteByStatut}
				isShowFilterStatus
			/>
			<div className="pepites-card-containers mt-3">
				<div className="flex-sb mb-3">
					<div className="flex-m gap-2">
						<h3 className="title__level3">Les pépites de la collection</h3>
						<BsSquareFill
							style={{
								fontSize: 7,
								color: "#999591",
							}}
						/>
						<p className="total-pepite  m-0">
							{formatPlurial(data?.count, "Résultat")}
						</p>
					</div>
					{isShowPagination ? (
						<div className="view-md">
							<Pagination
								title="Page"
								handlePageClick={handlePageClick}
								pageCount={pageCount}
							/>
						</div>
					) : null}
				</div>
			</div>
			<div className="row pt-3">
				<div className="d-flex justify-content-between align-items-center mb-3">
					{isSelectedProduct && data?.results && data?.results?.length > 0 && (
						<div className="form-group">
							<label htmlFor="select-all">Tout sélectionner</label>
							<input
								type="checkbox"
								className="form-check-input fas__check ms-2"
								style={{ cursor: "pointer" }}
								onChange={handleSelectAll}
								id="select-all"
							/>
						</div>
					)}
					{isShowBroadcastBtn && (
						<DeleteMultiplePepite
							produits={arrayOfProductsId}
							reset={reset}
							collection={currentCollection}
						/>
					)}
				</div>
				{!isLoading ? (
					pepites && pepites.length > 0 ? (
						pepites?.map((item) => (
							<PepiteCard
								key={item?.slug}
								path="/user/mon-stock/pepites/"
								pepite={item}
								getCurrentPepite={getCurrentPepite}
								sales={item?.sales}
								checkTokenStatus={onCheckTokenStatus}
								collectionSlug={currentCollection?.slug}
								onToggleCheckedItem={onToggleCheckedItem}
								arrayOfProductsId={arrayOfProductsId}
							/>
						))
					) : (
						<AlertInfo message="Aucun produit trouvé pour cette collection" />
					)
				) : (
					<>
						{[...Array(4)]?.map((item, i) => (
							<PepiteCardSkeleton key={i} />
						))}
					</>
				)}
			</div>
			<DiffuserModalPepite item={item} tokenStatus={tokenStatus} />
			<ModalDiffusionMassProducts
				tokenStatus={tokenStatus}
				reset={reset}
				arrayOfProductsId={arrayOfProductsId}
			/>
		</div>
	);
};

export function DeleteProductFromCollection(props: { item: IPepite; collectionSlug: string }) {
	const [deleteItem, { isSuccess, isError, error }] = useRemoveProductFromCollectionMutation();

	const { item, collectionSlug } = props;
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir retirer cette pépite de la collection ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				return deleteItem({ slug_p: item.slug, slug_c: collectionSlug });
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Pépite retirée de la collection avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Une erreur a survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isError]);
	return (
		<button className="btn_action btn__delete" data-bs-toggle="modal" onClick={onDelete}>
			Rétirer
		</button>
	);
}

export default DetailCollection;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/hooks";
import { useAddContactMutation } from "../../../../utils/api/contact/contact.api";
import { TContactForm } from "../../../../utils/api/contact/contact.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide } from "../../../../utils/utils";

const useContactForm = (isShowContact: boolean) => {
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Le nom"),
		email: yup.string().email().required().label("Email"),
		message: yup.string().required().label("Le message"),
		subject: yup.string().required().label("Le sujet"),
	});
	const { user: authUser } = useAppSelector((s) => s.user);
	const [message, setMessage] = useState<string>("");

	const [sendMessage, { isLoading, isError, isSuccess, error }] = useAddContactMutation();
	const ref = useRef<any>(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<TContactForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succés !",
				text: "Nous allons revenir vers vous au plus vite",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 4000,
			}).then(() => {
				reset();
				if (ref) {
					ref?.current?.editor.setText("");
				}
				if (!isShowContact) {
					onHide("ContactModal");
				}
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	useEffect(() => {
		if (authUser) {
			setValue("name", authUser?.prenom + " " + authUser?.nom);
			setValue("email", authUser?.email);
			setValue("phone", authUser?.telephone);
		}
	}, [authUser]);
	const handleChangeMessage = (value: string) => {
		setMessage(value);
		setValue("message", value);
	};
	const onsubmit = (data: TContactForm) => {
		console.log(data);
		sendMessage({ ...data, user: authUser?.id });
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		authUser,
		message,
		handleChangeMessage,
		ref,
	};
};

export default useContactForm;

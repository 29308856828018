import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { UserFormData } from "../../../../../utils/api/user/user.type";
import { Vendeur, VendeurFormData } from "../../../../../utils/api/vendeur/vendeur.type";
import { useEditVendeurMutation } from "../../../../../utils/api/vendeur/vendeur.api";

function useEditVendeur(user?: Vendeur) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		prenom: yup.string().required().label("Prénom"),
		telephone: yup.string().required().label("Téléphone"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm<VendeurFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [editVendeur, { isLoading, isSuccess, error, isError }] = useEditVendeurMutation();
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Vendeur modifié avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
			});
		}
	}, [isSuccess]);
	useEffect(() => {
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenue lors de la modification",
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: VendeurFormData) => {
		console.log(data);
		editVendeur({ slug: user?.slug, data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
	};
}

export default useEditVendeur;

import React, { useState } from "react";
import { BsSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/hooks";
import {
	useGetPepitesDiffuserByUserQuery,
	useLazyDiffusePepiteByScriptQuery,
} from "../../../../utils/api/pepite/pepite.api";
import { IDiffusedPepite, IPepite } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { calculatePageCount, useItem } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import Pagination from "../../../common/Pagination";
import PanelFilter from "../../../common/PanelFilter";
import DeletePepiteModal from "../../modals/user/DeletePepiteModal";
import ModifierDiffusionModalPepite from "../../modals/user/ModifierDiffusionModalPepite";
import { PepiteCardSkeleton, PepiteDiffusedCard } from "../Stock/PepiteCard";
import DeleteModalDiffusionPepite from "./DeleteModalDiffusionPepite";
import { useLazyCheckMarketPlaceTokenValidityQuery } from "../../../../utils/api/marketplace/marketplace.api";
import StopModalDiffusionPepite from "./StopModalDiffusionPepite";

const Diffuser = () => {
	const [page, setPage] = useState(1);
	const { user } = useAppSelector((s) => s.user);
	const { data, isLoading } = useGetPepitesDiffuserByUserQuery({
		page,
		limit: 10,
		slug: user?.slug,
	});
	const perPage = 10;
	const isData = data?.results && data?.results?.length > 0;
	console.log({ data });

	const isShowPaginate = data?.count && data?.count > perPage;

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};
	const [checkMarketPlaceTokenValidity, { data: tokenStatus }] =
		useLazyCheckMarketPlaceTokenValidityQuery();

	const [diffuser] = useLazyDiffusePepiteByScriptQuery();
	const navigate = useNavigate();
	const { item, getItem: getCurrentPepite } = useItem<IDiffusedPepite>();

	const onSell = async (slug: string) => {
		const res = await diffuser(slug);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Produit vendu avec succès",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2500,
			}).then(() => {
				navigate("/user/mes-ventes");
			});
		} else if ("error" in res) {
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenu lors de l'inscription",
				showConfirmButton: false,
				confirmButtonColor: Color.primary,
				timer: 2000,
			});
		}
	};
	return (
		<div className="diffusion__page">
			<h1>En ligne</h1>
			<PanelFilter />
			<div className="pepites__container mt-4 px-md-3 px-lg-0">
				<div className="flex-sb mb-3">
					<div className="d-flex justify-content-between w-100">
						<div className="flex-m gap-2">
							<h3 className="title__level3">Mes pépites en stock</h3>
							<BsSquare
								style={{
									fontSize: 7,
									color: "#999591",
								}}
							/>
							<p className="total-pepite  m-0">{data?.count} Résultat</p>
						</div>
					</div>
					{isShowPaginate && (
						<div className="view-md">
							<Pagination
								title="Page"
								pageCount={pageCount}
								handlePageClick={handlePageClick}
							/>
						</div>
					)}
				</div>
				<div className="pepites__cards__container">
					{!isLoading ? (
						isData ? (
							data?.results?.map((item: IDiffusedPepite) => (
								<React.Fragment key={item?.slug}>
									<PepiteDiffusedCard
										item={item}
										broadcast={true}
										path="/user/mon-stock/pepites/"
										marketplace={item?.marketplace}
										marketplace_detail={item?.marketplace_detail}
										onSell={onSell}
										sales={item.sales}
										checkTokenStatus={checkMarketPlaceTokenValidity}
										getCurrentPepite={getCurrentPepite}
									/>
								</React.Fragment>
							))
						) : (
							<AlertInfo message="Aucune pépite trouvée" />
						)
					) : (
						<>
							{[...Array(5)]?.map((item, i) => (
								<PepiteCardSkeleton key={i} />
							))}
						</>
					)}
				</div>
				{isShowPaginate ? (
					<div className="flex-c">
						<Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
					</div>
				) : null}
			</div>
			<DeleteModalDiffusionPepite
				item={item}
				tokenStatus={tokenStatus}
				marketplace={item?.marketplace}
			/>
			<StopModalDiffusionPepite
				item={item}
				tokenStatus={tokenStatus}
				marketplace={item?.marketplace}
			/>
			<ModifierDiffusionModalPepite />
			<DeletePepiteModal message="En supprimant cette pépite, elle sera automatiquement retirée de notre plateforme et des marketplaces." />
		</div>
	);
};

export default Diffuser;

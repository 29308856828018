import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { useAddOrUpdateBoutiqueMutation } from "../../../../../utils/api/boutique/boutique.api";
import { BoutiqueFormData, IBoutique } from "../../../../../utils/api/boutique/boutique.type";
import { useAppDispatch } from "../../../../../redux/hooks";
import { useLazyMeQuery } from "../../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../../redux/slice/User.slice";

function UseEditInfoBoutique(isFetch: boolean, boutique?: IBoutique) {
	const validationSchema = yup.object().shape({
		nom_boutique: yup.string().required().label("Nom boutique"),
		numero_siret: yup
			.number()
			.required()
			.label("Numéro de s")
			.typeError("Numéro de siren est un champ obligatoire"),
		adresse: yup.string().required().label("Adresse"),
		ville: yup.string().required().label("Ville"),
		code_postal: yup.string().required().label("Code Postal"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
	} = useForm<BoutiqueFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [editBoutique, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateBoutiqueMutation();
	const [findMe, { data }] = useLazyMeQuery();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (data) {
			console.log({ data });
			dispatch(onSetUser(data));
		}
	}, [data]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Informations de la boutique modifiées avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				if (isFetch) {
					findMe();
				}
			});
		}
	}, [isSuccess]);
	useEffect(() => {
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenue lors de la modification",
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: BoutiqueFormData) => {
		if (String(data?.numero_siret)?.length < 9) {
			return setError("numero_siret", {
				message: "Le numero est inférieur à 9 chiffres",
			});
		}
		// console.log(data);
		return editBoutique({ slug: boutique?.slug, data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
	};
}

export default UseEditInfoBoutique;

import React from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import Crushon from "../../../../assets/images/Diffusion/Crushon.png";
import MarketPlace from "../../../../assets/images/Diffusion/MarketPlace.png";
import { IDiffusedPepite, IPepite } from "../../../../utils/api/pepite/pepite.type";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { checkIfProductIsBroadcastAllMarket, getImage } from "../../../../utils/utils";
import { IMarketPlace, ItokenStatus } from "../../../../utils/api/marketplace/marketplace.type";
import ebay from "../../../../assets/images/Diffusion/Ebay.png";
import Etsy from "../../../../assets/images/Diffusion/etsy.png";
import { DeleteProductFromCollection } from "../collection/DetailCollection";

interface PepiteCardProps {
	pepite: IPepite;
	path?: string;
	broadcast?: boolean;
	getCurrentPepite?: (item: IPepite) => void;
	checkTokenStatus?: () => void;
	marketplace?: string;
	marketplace_detail?: IMarketPlace;
	onSell?: (slug: string) => void;
	sales?: boolean;
	onToggleCheckedItem?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	arrayOfProductsId?: number[];
	collectionSlug?: string;
	tokenStatus?: ItokenStatus;
}
interface PepiteDiffusedCardProps {
	item: IDiffusedPepite;
	path?: string;
	broadcast?: boolean;
	getCurrentPepite?: (item: IDiffusedPepite) => void;
	checkTokenStatus?: () => void;
	marketplace?: string;
	marketplace_detail?: IMarketPlace;
	onSell?: (slug: string) => void;
	sales?: boolean;
	onToggleCheckedItem?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	arrayOfProductsId?: number[];
	collectionSlug?: string;
	tokenStatus?: ItokenStatus;
}
interface PepiteProps {
	pepite?: IPepite;
}

const PepiteCard: React.FC<PepiteCardProps> = (props) => {
	const {
		pepite,
		path,
		broadcast,
		getCurrentPepite,
		marketplace_detail,
		// onSell,
		sales,
		checkTokenStatus,
		onToggleCheckedItem,
		arrayOfProductsId,
		collectionSlug,
	} = props;
	return (
		<div className="fashion__card px-1 py-2 mb-3 gap-3 d-flex flex-column-reverse  align-items-end flex-md-row align-items-center">
			{onToggleCheckedItem && (
				<div>
					<input
						type="checkbox"
						name={pepite?.nom}
						value={pepite?.id}
						className="form-check-input fas__check"
						style={{ cursor: "pointer" }}
						onChange={onToggleCheckedItem}
						checked={arrayOfProductsId?.includes(Number(pepite?.id))}
					/>
				</div>
			)}
			<div className="row pepite__item flex-fill">
				<div className="col-md-6  position-relative">
					<div className="pepite-left-block d-md-flex gap-md-3">
						<PepiteInfo pepite={pepite} />
					</div>
				</div>
				<div className="flex-m-sb col-md-6">
					<div className="group-info">
						{broadcast ? (
							<>
								<h5 className="group_info_title">Diffusé sur :</h5>
								<div className="flex-m gap-2">
									{marketplace_detail && (
										<div className="img__container">
											<img
												src={getImage(marketplace_detail?.logo)}
												className="logo-brodcast"
												alt={marketplace_detail.nom}
											/>
										</div>
									)}
									<span className="fas__label">
										{sales === true ? "Vendu" : "Pas encore vendu"}
									</span>
								</div>
							</>
						) : (
							<>
								{sales && <h5 className="group_info_title">statut</h5>}
								<div className="position-relative ps-3">
									{sales === true ? (
										<h6 className={`group_info_statut sales`}>Vendu</h6>
									) : (
										<>
											{pepite?.diffusions?.length > 0 && (
												<>
													<h5 className="group_info_title">
														Diffusé sur :
													</h5>
													{pepite?.diffusions?.map((item) => {
														if (item?.marketplace === "etsy") {
															return (
																<img
																	src={Etsy}
																	alt="etsy"
																	width="50px"
																	key={item?.marketplace}
																/>
															);
														} else if (item?.marketplace === "ebay") {
															return (
																<img
																	src={ebay}
																	alt="ebay"
																	width="50px"
																	className="me-3"
																	key={item?.marketplace}
																/>
															);
														}
														return null;
													})}
												</>
											)}
										</>
									)}
									{!pepite?.en_ligne && !pepite?.diffusions?.length && (
										<h6 className={`group_info_statut  ebauche`}>Ébauche</h6>
									)}
								</div>
							</>
						)}
					</div>
					<div className="flex-m gap-3">
						{path && (
							<Link to={path + pepite?.slug} className="btn__show" state={pepite}>
								Voir
							</Link>
						)}
						{getCurrentPepite && checkTokenStatus && (
							<button
								className={`${
									checkIfProductIsBroadcastAllMarket(pepite?.diffusions)
										? "btn__disabled"
										: ""
								} btn__show`}
								data-bs-toggle="modal"
								data-bs-target="#DiffuserModalPepite"
								onClick={() => {
									getCurrentPepite(pepite);
									checkTokenStatus();
								}}
								disabled={checkIfProductIsBroadcastAllMarket(pepite?.diffusions)}
							>
								Diffuser
							</button>
						)}
						<div className="dropdown">
							<button
								className="dropdown-toggle"
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<BsThreeDotsVertical />
							</button>
							<ul className="dropdown-menu fashion__card pepite_action_container mt-2">
								<div className="d-flex flex-column align-items-end px-2 gap-2">
									<li>
										<Link
											to={`/user/mon-stock/pepites/${pepite?.slug}/modifier`}
											className="btn_action btn__edit"
											state={pepite}
										>
											Modifier
										</Link>
									</li>
									<li>
										{pepite?.diffusions?.length > 0 ? (
											<button
												className="btn_action btn__delete"
												data-bs-toggle="modal"
												data-bs-target="#DeleteModalPepite"
												disabled={true}
												title="Arrêter la diffusion avant de pouvoir supprimer le produit"
											>
												{/* Arrêter */}
												supprimer
											</button>
										) : (
											<>
												{!collectionSlug && getCurrentPepite && (
													<button
														className="btn_action btn__delete"
														data-bs-toggle="modal"
														data-bs-target="#DeleteModalPepite"
														onClick={() => getCurrentPepite(pepite)}
													>
														supprimer
													</button>
												)}
											</>
										)}
									</li>

									{collectionSlug && (
										<li>
											<DeleteProductFromCollection
												item={pepite}
												collectionSlug={collectionSlug}
											/>
										</li>
									)}
								</div>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export function PepiteInfo({ pepite }: PepiteProps) {
	return (
		<>
			<div className="pepite_img_container">
				<img
					src={
						pepite?.photo_principale
							? getImage(pepite?.photo_principale)
							: getImage(pepite?.images[0]?.picture)
					}
					alt=""
					className="pepite__img mb-2"
				/>
			</div>
			<div className="pepites__infos flex-fill pe-3">
				<h4 className="pepite__title">{pepite?.nom}</h4>
				<div className="flex-sb ">
					<div className="group-info">
						<h5 className="group_info_title">Référence</h5>
						<h6 className="group_info_value">{pepite?.reference?.slice(0, 15)}</h6>
					</div>
					<div className="group-info">
						<h5 className="group_info_title">Taille</h5>
						<h6 className="group_info_value">{pepite?.taille[0]?.nom}</h6>
					</div>
					<div className="group-info">
						<h5 className="group_info_title">Prix</h5>
						<h6 className="group_info_value">{pepite?.prix}€</h6>
					</div>
				</div>
			</div>
		</>
	);
}
export function SmallPepiteCard({ pepite }: PepiteProps) {
	return (
		<div className="d-md-flex gap-md-3 fashion__card">
			<PepiteInfo pepite={pepite} />
		</div>
	);
}

export const PepiteCardSkeleton = ({ broadcast }: { broadcast?: boolean }) => {
	return (
		<div className="fashion__card px-1 py-2 row mb-3 pepite__item">
			<div className="col-md-6  position-relative">
				<div className="d-md-flex gap-md-3">
					<div className="pepite_img_container">
						<VolkenoSkeleton variant="rect" width="90px" height="90px" />
					</div>
					<div className="pepites__infos flex-fill pe-3">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
						<div className="flex-sb ">
							<div className="group-info">
								<VolkenoSkeleton variant="rect" width={100} height={10} />
								<VolkenoSkeleton variant="rect" width={100} height={10} />
							</div>
							<div className="group-info">
								<VolkenoSkeleton variant="rect" width={100} height={10} />
								<VolkenoSkeleton variant="rect" width={100} height={10} />
							</div>
							<div className="group-info">
								<VolkenoSkeleton variant="rect" width={100} height={10} />
								<VolkenoSkeleton variant="rect" width={100} height={10} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex-m-sb col-md-6">
				<div className="group-info">
					{broadcast ? (
						<>
							<h5 className="group_info_title">Diffusé sur :</h5>
							<div className="flex-m gap-2">
								<div className="img__container">
									<img src={Crushon} alt="Crushon" className="logo-brodcast" />
								</div>
								<div className="img__container">
									<img
										src={MarketPlace}
										alt="MarketPlace"
										className="logo-brodcast"
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<VolkenoSkeleton variant="rect" width={100} height={10} />
							<VolkenoSkeleton variant="rect" width={100} height={10} />
						</>
					)}
				</div>
				<div className="flex-m gap-3">
					<VolkenoSkeleton variant="rect" width={50} height={20} />
					<VolkenoSkeleton variant="rect" width={5} height={25} />
				</div>
			</div>
		</div>
	);
};

export const PepiteDiffusedCard: React.FC<PepiteDiffusedCardProps> = (props) => {
	const {
		item,
		path,
		broadcast,
		getCurrentPepite,
		marketplace_detail,
		sales,
		checkTokenStatus,
		onToggleCheckedItem,
		arrayOfProductsId,
	} = props;
	return (
		<div className="fashion__card px-1 py-2 mb-3 gap-3 d-flex flex-column-reverse  align-items-end flex-md-row align-items-center">
			{onToggleCheckedItem && (
				<div>
					<input
						type="checkbox"
						name={item?.produit?.nom}
						value={item?.produit?.id}
						className="form-check-input fas__check"
						style={{ cursor: "pointer" }}
						onChange={onToggleCheckedItem}
						checked={arrayOfProductsId?.includes(Number(item?.produit?.id))}
					/>
				</div>
			)}
			<div className="row pepite__item flex-fill">
				<div className="col-md-6  position-relative">
					<div className="pepite-left-block d-md-flex gap-md-3">
						<PepiteInfo pepite={item?.produit} />
					</div>
				</div>
				<div className="flex-m-sb col-md-6">
					<div className="group-info">
						{broadcast ? (
							<>
								<h5 className="group_info_title">Diffusé sur :</h5>
								<div className="flex-m gap-2">
									{marketplace_detail && (
										<div className="img__container">
											<img
												src={getImage(marketplace_detail?.logo)}
												className="logo-brodcast"
												alt={marketplace_detail.nom}
											/>
										</div>
									)}
									<span className="fas__label">
										{sales === true ? "Vendu" : "Pas encore vendu"}
									</span>
								</div>
							</>
						) : (
							<>
								{sales && <h5 className="group_info_title">statut</h5>}
								<div className="position-relative ps-3">
									{sales === true ? (
										<h6 className={`group_info_statut sales`}>Vendu</h6>
									) : (
										<>
											{item?.produit?.diffusions?.length > 0 && (
												<>
													<h5 className="group_info_title">
														Diffusé sur :
													</h5>
													{item?.produit?.diffusions?.map((item) => {
														if (item?.marketplace === "etsy") {
															return (
																<img
																	src={Etsy}
																	alt="etsy"
																	width="50px"
																	key={item?.marketplace}
																/>
															);
														} else if (item?.marketplace === "ebay") {
															return (
																<img
																	src={ebay}
																	alt="ebay"
																	width="50px"
																	className="me-3"
																	key={item?.marketplace}
																/>
															);
														}
														return null;
													})}
												</>
											)}
										</>
									)}
									{!item?.produit?.en_ligne &&
										!item?.produit?.diffusions?.length && (
											<h6 className={`group_info_statut  ebauche`}>
												Ébauche
											</h6>
										)}
								</div>
							</>
						)}
					</div>
					<div className="flex-m gap-3">
						{path && (
							<Link
								to={path + item?.produit?.slug}
								className="btn__show"
								state={item?.produit}
							>
								Voir
							</Link>
						)}
						{/* {onSell && (
							<button className="btn__show" onClick={() => onSell(item?.produit.slug)}>
								Vendre
							</button>
						)} */}
						{getCurrentPepite && checkTokenStatus && (
							<button
								className={`btn__show`}
								data-bs-toggle="modal"
								data-bs-target="#StopModalDiffusionPepite"
								onClick={() => {
									getCurrentPepite(item);
									checkTokenStatus();
								}}
							>
								Arrêter
							</button>
						)}
						<div className="dropdown">
							<button
								className="dropdown-toggle"
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<BsThreeDotsVertical />
							</button>
							<ul className="dropdown-menu fashion__card pepite_action_container mt-2">
								<div className="d-flex flex-column align-items-end px-2 gap-2">
									<li>
										{checkTokenStatus && getCurrentPepite && (
											<>
												<button
													className="btn_action btn__delete"
													data-bs-toggle="modal"
													data-bs-target="#DeleteModalDiffusionPepite"
													onClick={() => {
														checkTokenStatus();
														getCurrentPepite(item);
													}}
												>
													supprimer
												</button>
											</>
										)}
									</li>
								</div>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PepiteCard;

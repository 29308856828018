import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import {
	IMarketPlace,
	MarketPlaceFormData,
} from "../../../../../utils/api/marketplace/marketplace.type";
import { useAddOrUpdateMarketPlaceMutation } from "../../../../../utils/api/marketplace/marketplace.api";

function useCrudMarketPlaceForm(closeModal: () => void, item?: IMarketPlace, setItem?: any) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		logo: yup.mixed().required().label("Logo"),
		lien: yup.string().label("Lien"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<MarketPlaceFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			slug: item?.slug,
		},
	});

	const [logoMarket, setLogoMarket] = useState<string>();

	const [addOrUpdate, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateMarketPlaceMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item
					? "Marketplace modifié avec succès !"
					: "Marketplace ajouté avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				closeModal();
				setItem(undefined);
				setLogoMarket(undefined);
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("lien", item?.lien);
			setValue("nom", item?.nom);
		} else {
			setValue("lien", "");
			setValue("nom", "");
		}
	}, [item]);

	const onChangeLogoMarket = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];
			console.log(file);
			setLogoMarket(URL.createObjectURL(file));
			setValue("logo", e.target.files[0]);
		}
	};

	const onSubmit = (data: MarketPlaceFormData) => {
		console.log(data);
		let fd = new FormData();
		if (data?.logo) {
			fd.append("logo", data.logo);
		}
		fd.append("lien", data.lien);
		fd.append("nom", data.nom);
		addOrUpdate({ slug: item?.slug, data: fd });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		logoMarket,
		onChangeLogoMarket,
	};
}

export default useCrudMarketPlaceForm;

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect } from "react";
import Swal from "sweetalert2";
import { useAddOrUpdateCategoryMutation } from "../../../../../utils/api/category/category.api";
import { CategoryFormData, ICategory } from "../../../../../utils/api/category/category.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";

function useUserForm(closeModal: () => void, item?: ICategory) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
		setError,
	} = useForm<CategoryFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdate, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateCategoryMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item
					? "Catégorie modifiée avec succès !"
					: "Catégorie ajoutée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				closeModal();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	useEffect(() => {
		if (item) {
			setValue("nom", item?.nom);
		} else {
			setValue("nom", "");
		}
	}, [item]);

	const onSubmit = (data: CategoryFormData) => {
		if (data?.nom?.trim() === "") {
			return setError("nom", { message: "Catégorie est un champ obligatoire" });
		}
		addOrUpdate({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useUserForm;

import React from "react";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import { CustomPagination } from "../../../common/CustomPagination";
import BootstrapTable from "react-bootstrap-table-next";
import AddPaiementModeModal from "../../modals/admin/AddPaiementModeModal";
import {
	useDeletePaymentModeMutation,
	useEditPaymentModeMutation,
	useGetPaymentModeQuery,
} from "../../../../utils/api/paymentMode/paymentMode.api";
import { PaymentMode } from "../../../../utils/api/paymentMode/paymentMode.type";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import { useDelete } from "../../../../utils/helpers";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import TableSkeleton from "../../../common/TableSkeleton";

interface ModePaiementProps {
	isShowModal: boolean;
	openEditModal: (item: PaymentMode) => void;
	closeModal: () => void;
	item?: PaymentMode;
}

const ModePaiement = ({ isShowModal, openEditModal, closeModal, item }: ModePaiementProps) => {
	const [page, setPage] = React.useState(1);
	const [perPage, setPerPage] = React.useState(10);
	const { isLoading, data: { results: paymentModes, count } = { results: [], count: 0 } } =
		useGetPaymentModeQuery({
			word: "",
			page,
			limit: perPage,
		});

	const [editData] = useEditPaymentModeMutation();

	const onActive = async (item: PaymentMode) => {
		let data = {
			active: !item?.active,
			slug: item?.slug,
		};
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir ${
				item?.active === true ? "désactiver" : "activer"
			} ce mode de paiement?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			confirmButtonColor: Color.primary,
			// iconColor: Color.red,
			preConfirm: () => {
				return editData(data);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Mode de paiement ${
						result?.value?.data?.active ? "activé" : "désactivé"
					} avec succès!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const actionFormatter: any = (cell: string, row: PaymentMode) => {
		return (
			<div className="gap-3 d-flex">
				<div>
					<button
						className={`action-btn ${
							row?.active ? "action-btn-delete" : "action-btn-view"
						} `}
						title={row?.active ? "Désactiver" : "Activer"}
						onClick={() => onActive(row)}
					>
						{!row?.active ? <RiCloseFill /> : <RiCheckFill />}
					</button>
				</div>
				<ButtonEdit title="Modifier" onClick={() => openEditModal(row)} />
				<DeleteModePaiement item={row} />
			</div>
		);
	};
	const columns = [
		{
			dataField: "titre",
			text: " Nom",
			style: { textAlign: "left" },
		},
		{
			dataField: "nom",
			text: "Type",
			style: { textAlign: "left" },
		},

		{
			dataField: "slug",
			text: "actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: PaymentMode) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "150px" };
			},
		},
	];

	return (
		<div className="px-3 py-5">
			<div className="flex-sb mb-2">
				<h3 className="params-title admin-title">Mode de paiement utilisé</h3>
			</div>
			{isLoading && <TableSkeleton headers={["Nom", "Type", "Actions"]} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={paymentModes || []}
						columns={columns}
						noDataIndication="Aucune donnée disponible"
						bordered={false}
						condensed={false}
						responsive
						wrapperClasses="table-responsive admin-table"
					/>
					<CustomPagination
						nbPages={count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
			<AddPaiementModeModal item={item} isOpen={isShowModal} closeModal={closeModal} />
		</div>
	);
};

export function DeleteModePaiement({ item }: { item: PaymentMode }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeletePaymentModeMutation();
	const onDelete = useDelete<PaymentMode>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet mode de paiement ?",
		successMessage: "Mode de paiement supprimé",
	});
	return <ButtonDelete onClick={onDelete} />;
}

export default ModePaiement;

import React from "react";
import { ModalBody } from "reactstrap";
import { Modal } from "reactstrap";
import { ICollection } from "../../../../utils/api/collection/collection.type";
import { FormModalProps } from "../../../../utils/type";
import { getImage } from "../../../../utils/utils";
import { BtnPrimary, CloseModalButton } from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import useCrudCollection from "../../user/collection/hooks/useCrudCollection";

const AddCollectionModal = ({ item, setItem, isOpen, closeModal }: FormModalProps<ICollection>) => {
	const { register, onSubmit, errors, onChangeImage, imageCollection, isLoading } =
		useCrudCollection(closeModal, item, setItem);
	return (
		<Modal isOpen={isOpen} centered>
			<div className="modal-content fas__modal  px-3 py-4">
				<div className="container-fluid mx-auto">
					<div className="flex-sb">
						<h4 className="fas-modal-title mb-4">
							{item ? "Modifier la" : "Créer une "} collection
						</h4>
						<CloseModalButton onClick={closeModal} />
					</div>
					<ModalBody>
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-12">
									<label htmlFor="libelle">Titre collection</label>
									<Input
										label="Titre collection*"
										id="libelle"
										type="text"
										placeholder="Titre collection"
										{...register("libelle")}
										error={errors?.libelle}
									/>
								</div>
								<div className="col-12 mb-2">
									<div className="form-group">
										<label htmlFor="image">Image</label>
										<input
											id="image"
											type="file"
											placeholder="Logo"
											onChange={onChangeImage}
											multiple={false}
											className="form-control form-custom-control form-control-lg py-3"
										/>
										<FormError error={errors?.image} />
									</div>
								</div>
								{(imageCollection || item?.image) && (
									<div className="col-12 mb-3">
										<img
											src={
												imageCollection
													? imageCollection
													: getImage(item?.image)
											}
											alt="w-100"
											style={{ maxHeight: "200px" }}
											className="img-thumbnail"
										/>
									</div>
								)}
							</div>
							<div className="flex-end mt-5 gap-4">
								<CloseModalButton text="Fermer" onClick={closeModal} />
								<BtnPrimary
									label="Enregistrer"
									mode="Secondary"
									isLoading={isLoading}
								/>
							</div>
						</form>
					</ModalBody>
				</div>
			</div>
		</Modal>
	);
};

export default AddCollectionModal;

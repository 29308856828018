import React, { useState } from "react";
import { BtnPrimary } from "../../../../common/Button";
import ImgAdd from "../../../../../assets/images/Photoadd.png";
import { IconImage } from "../../../../shared/Icons";
import { IStepsCrudPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { FormError } from "../../../../common/Input";
import { getImage } from "../../../../../utils/utils";
import { AiFillDelete } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

const titles = [
	// "Photo principale",
	"Photo n°2",
	"Photo n°3",
	"Photo n°4",
	"Photo n°5",
	"Photo n°6",
	"Photo n°7",
];

const Photos: React.FC<IStepsCrudPepiteProps> = ({
	onSubmit,
	errors,
	images,
	onSelectImages,
	item,
	onDeleteSelectedImageItem,
	onDeleteImage,
	photo,
	onSelectPhoto,
}) => {
	return (
		<>
			<h2>Rendez-la encore plus attrayante !</h2>
			<h4 className="info__message">
				Téléchargez vos photos. Avec plus de photos vous augmentez vos chances de vendre 😉
			</h4>
			<form action="" className="pt-4" onSubmit={onSubmit}>
				<div className="d-flex justify-content-center justify-content-sm-start flex-wrap gap-4 mb-3">
					<div className="d-flex align-items-center gap-4">
						<label
							htmlFor="photo_principale"
							className="label__photo"
							style={{ cursor: "pointer" }}
						>
							<div className="photo-p">
								<FiUpload />
								<span>Choisir la photo principale</span>
							</div>
							<input
								type="file"
								id="photo_principale"
								accept="image/*"
								className="invisible v-none"
								onChange={(e) => onSelectPhoto(e)}
								multiple={false}
							/>
						</label>
						{photo && (
							<div className="photo-container position-relative">
								<img
									src={URL.createObjectURL(photo)}
									alt=""
									style={{
										border: "1px dashed #CCCAC8",
										width: "100px",
										height: "100px",
									}}
								/>
							</div>
						)}
					</div>
					<FormError error={errors?.photo_principale?.message} />
				</div>
				<div>
					<label htmlFor="taille" className="custom-form-label mb-2">
						Autres photos
					</label>
					{/* <p className="required-text mb-2">2 photos minimum</p> */}
					<div className="d-flex justify-content-center justify-content-sm-start flex-wrap gap-4">
						<div className="img-container">
							<label
								htmlFor="photo"
								className="label__photo"
								style={{ cursor: "pointer" }}
							>
								<img src={ImgAdd} alt="" />
								<input
									type="file"
									id="photo"
									accept="image/*"
									className="invisible v-none"
									onChange={(e) => onSelectImages(e)}
									multiple={true}
								/>
							</label>
						</div>
						{images?.length > 0 &&
							images?.map((item: File, i: number) => (
								<div className="photo-container position-relative" key={item.name}>
									<img
										src={URL.createObjectURL(item)}
										alt=""
										style={{
											border: "1px dashed #CCCAC8",
											width: "150px",
											height: "150px",
										}}
									/>
									<div className="photo-overlay" role="button">
										<button
											className="btn-delete-photo"
											role="button"
											onClick={(e) => onDeleteSelectedImageItem(e, i)}
										>
											<AiFillDelete />
										</button>
									</div>
								</div>
							))}
						{item &&
							item?.images?.map((img) => (
								<div className="photo-container position-relative" key={item.slug}>
									<img
										src={getImage(img?.picture)}
										alt={img?.slug}
										style={{
											border: "1px dashed #CCCAC8",
											width: "150px",
											height: "150px",
										}}
									/>
									<div className="photo-overlay" role="button">
										<button
											className="btn-delete-photo"
											role="button"
											onClick={(e) => onDeleteImage(e, img?.slug)}
										>
											<AiFillDelete />
										</button>
									</div>
								</div>
							))}
						{titles?.map((elem, index) => (
							<div
								className={`d-flex flex-column align-items-center justify-content-center gap-3 ${
									(images?.length > index || item?.images?.length > index) &&
									"d-none"
								}`}
								style={{
									border: "1px dashed #CCCAC8",
									width: "150px",
									height: "150px",
								}}
								key={index}
							>
								<IllustrePhotoItem text={elem} />
							</div>
						))}
					</div>
				</div>
				<FormError error={errors?.images?.message} />
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</>
	);
};

export function IllustrePhotoItem({ text }: { text: string }) {
	return (
		<>
			<IconImage />
			<span
				style={{
					fontWeight: 700,
					color: "#CCCAC8",
					fontFamily: "Urbanist",
					fontSize: "12px",
					lineHeight: "18px",
				}}
			>
				{text}
			</span>
		</>
	);
}

export default Photos;

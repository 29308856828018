import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { ITaille, TailleFormData } from "../../../../../utils/api/taille/taille.type";
import { useAddOrUpdateTailleMutation } from "../../../../../utils/api/taille/taille.api";

function useCrudTaille(closeModal: () => void, item?: ITaille) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("La Taille"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<TailleFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			id: item?.id,
		},
	});

	const [addOrUpdate, { isLoading, isSuccess, error, isError }] = useAddOrUpdateTailleMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item ? "Taille modifiée avec succès !" : "Taille ajoutée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				if (!item) {
					reset();
				}
				closeModal();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		if (item) {
			setValue("nom", item?.nom);
		}
	}, [item]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: TailleFormData) => {
		console.log(data);
		addOrUpdate({ slug: item?.slug, data: data });
	};
	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useCrudTaille;

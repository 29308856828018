import ReactQuill from "react-quill";
import { BtnPrimary, CloseModalButton } from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import { IconNote } from "../../../shared/Icons";
import "./Contact.css";
import useContactForm from "./useContactForm";

const ContactForm = ({ isShowContact }: { isShowContact: boolean }) => {
	const { register, onSubmit, errors, isLoading, authUser, message, handleChangeMessage, ref } =
		useContactForm(isShowContact);
	return (
		<div className="contact-form">
			<div className={`${isShowContact ? "col-md-8" : "col-md-12"} mb-4`}>
				<div className="d-flex justify-content-between align-items-center">
					<h3 className="contact-tile">Formulaire de contact</h3>
					{!isShowContact && <CloseModalButton className="mb-3" />}
				</div>
				<p>
					Une question ? Contactez-nous ici. Nous vous répondrons dans les meilleurs
					délais. 😀
				</p>
			</div>
			<div className="row">
				<div className={`${isShowContact ? "col-md-8" : "col-md-12"}`}>
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-6 mb-3">
								<Input
									label="Votre nom"
									type="text"
									id="name"
									{...register("name")}
									error={errors?.name}
									defaultValue={
										authUser && authUser?.prenom + " " + authUser?.nom
									}
									disabled={authUser?.nom}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									label="Numero de telephone"
									type="text"
									id="phone"
									{...register("phone")}
									error={errors?.phone}
									defaultValue={authUser?.telephone}
									disabled={authUser?.telephone}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									label="Email"
									type="text"
									id="email"
									{...register("email")}
									error={errors?.email}
									defaultValue={authUser?.email}
									disabled={authUser?.email}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									label="Sujet"
									type="text"
									id="subject"
									{...register("subject")}
									error={errors?.subject}
								/>
							</div>
							<div className="col-12">
								<label htmlFor="description" className="form-first-label">
									Message*
								</label>
								<div className="form-group">
									<ReactQuill
										defaultValue={message}
										onChange={handleChangeMessage}
										ref={ref}
									/>
								</div>
								<FormError error={errors?.message} />
							</div>
						</div>
						<div className="btn-container flex-end mt-5">
							<BtnPrimary label="Envoyer" mode="Secondary" isLoading={isLoading} />
						</div>
					</form>
				</div>
				{isShowContact && (
					<div className="col-md-4">
						<div>
							<h5>Contact</h5>
							<div>
								<IconNote />
							</div>
							<p>+33 000 00 00 +33 000 00 00 00</p>
							<p>tftsolution@gmail.com</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactForm;

import { useState } from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { IFaq } from "../../../../utils/api/faq/faq.type";

const FaqItem = ({ id, faq }: { id: number; faq: IFaq }) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const toggleSetActive = () => {
		setIsActive((isActive) => !isActive);
	};
	return (
		<div className="faq__card shadow px-3 py-4 mb-3">
			<div
				className="flex-sb align-items-center  w-100"
				role="button"
				data-bs-toggle="collapse"
				data-bs-target={`#collapseFaq${id}`}
				aria-expanded="false"
				aria-controls="collapseFaq"
				onClick={toggleSetActive}
			>
				<h3 className="faq_item_title">{faq?.titre}</h3>
				<div className="accordion_icon_container">
					{isActive ? (
						<BiChevronDown style={{ fontSize: 22, color: "#F2994A" }} />
					) : (
						<BiChevronRight style={{ fontSize: 22, color: "#F2994A" }} />
					)}
				</div>
			</div>
			<div className="collapse mt-3" id={`collapseFaq${id}`}>
				<div className="faq__card__body">
					<p>{faq?.description?.replace(/(<([^>]+)>)/gi, "")}</p>
				</div>
			</div>
		</div>
	);
};

export default FaqItem;

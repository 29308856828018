import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../../redux/hooks";
import { useResponseContactMutation } from "../../../../../utils/api/contact/contact.api";
import { IContact, TResponseContact } from "../../../../../utils/api/contact/contact.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";

const useResponseContactForm = (item?: IContact) => {
	const validationSchema = yup.object().shape({
		response: yup.string().required().label("Le réponse"),
	});
	const { user: authUser } = useAppSelector((s) => s.user);
	const [response, setResponse] = useState<string>("");
	const [sendMessage, { isLoading, isError, isSuccess, error }] = useResponseContactMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<TResponseContact>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				setResponse("");
				reset();
				// onHide("DetailsMessageModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleChangeMessage = (value: string) => {
		setResponse(value);
		setValue("response", value);
	};
	const onsubmit = (data: TResponseContact) => {
		// console.log(data);
		sendMessage({ ...data, user: authUser?.id, message: item?.id as number });
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		response,
		handleChangeMessage,
		setResponse,
	};
};

export default useResponseContactForm;

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { ChangePasswordData } from "../../../../../utils/api/auth/auth.type";
import { useChangePasswordMutation } from "../../../../../utils/api/user/user.api";
import { Color } from "../../../../../utils/theme";
import { onHide } from "../../../../../utils/utils";
import { validatePassword } from "../../../auth/useAuthForm/useSignupForm";

function UseFormChangePassword() {
	const validationSchema = yup.object().shape({
		old_password: yup.string().required().label("L'ancien mot de passe"),
		new_password: validatePassword(yup),
		confirm_password: yup
			.string()
			.oneOf(
				[yup.ref("new_password"), null],
				"veuillez entrer des mots de passe identiques."
			),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
		reset,
	} = useForm<ChangePasswordData>({
		resolver: yupResolver(validationSchema),
	});

	const [changePassword, { isLoading, isSuccess, error, isError }] = useChangePasswordMutation();
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Mot de passe modifiée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				onHide("ChangePasswordModal");
			});
		}
	}, [isSuccess]);
	useEffect(() => {
		const err = error as any;
		if (isError) {
			if (err) {
				console.log(err?.data?.old_password);
				if (err?.data?.old_password?.includes("Wrong password.")) {
					setError("old_password", {
						message: "Le mot de passe est incorrect !",
					});
				} else {
					Swal.fire({
						icon: "error",
						title: err?.data?.message
							? err?.data?.message
							: "Une erreur a survenue lors de la modification",
						showConfirmButton: false,
						timer: 3000,
					});
				}
			}
		}
	}, [isLoading]);
	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				Object.entries(errors).map(([key]: any) => clearErrors(key));
			}, 4000);
		}
	}, [errors]);

	const onSubmit = (data: ChangePasswordData) => {
		console.log(data);
		changePassword(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
	};
}

export default UseFormChangePassword;

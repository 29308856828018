import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { DeviseType, IOffer, OfferFormData } from "../../../../../utils/api/offer/offer.type";
import {
	useAddOrUpdateOfferMutation,
	useDeleteOfferMutation,
} from "../../../../../utils/api/offer/offer.api";
import { useNavigate } from "react-router-dom";

function UseCrudAbonnement(item?: IOffer) {
	const [response, setResponse] = React.useState<any>("");
	const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Nom  de l’abonnement"),
		price: yup.string().required().label("Prix"),
		option: yup.string().required().label("Type d'abonnement"),
		frequence: yup
			.string()
			.required()
			.label("Fréquence")
			.typeError("Fréquence est un champ obligatoire"),
		description: yup.string().required().label("Description des avantages"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<OfferFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateOffer, { isLoading, isSuccess, error, isError, data }] =
		useAddOrUpdateOfferMutation();
	useEffect(() => {
		if (isSuccess) {
			console.log("data", data);
			Swal.fire({
				icon: "success",
				title: `Abonnement ${!item ? "ajouté" : "modifié"} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				navigate("/admin/abonnements");
				reset();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			let fields: (keyof OfferFormData)[] = [
				"title",
				"price",
				"description",
				"details",
				"frequence",
				"option",
				"position",
			];
			for (let field of fields) {
				register(field);
				setValue(field, item[field]);
			}
			setResponse(item?.description);
		}
	}, [item]);

	const handleChange = (value: any) => {
		setResponse(value);
		setValue("description", value);
	};

	const onSubmit = (data: OfferFormData) => {
		console.log("data", data);
		const newData = { ...data, devise: DeviseType.euro };
		addOrUpdateOffer({ slug: item?.slug, data: newData });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		response,
		handleChange,
	};
}

export function useDeleteOffer(slug: IOffer["slug"]) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteOfferMutation();
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer cet abonnement`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				return deleteItem(slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Abonnement supprimé avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ?? `Une erreur de status ${err?.status} est survenue !`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}


export default UseCrudAbonnement;

import moment from "moment";
import React, { useState } from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";
import {
	useDeleteContactMutation,
	useGetContactMessagesQuery,
} from "../../../../utils/api/contact/contact.api";
import { IContact } from "../../../../utils/api/contact/contact.type";
import { useDelete } from "../../../../utils/helpers";
import { ButtonStatusStyle } from "../../../../utils/theme";
import { formatMessageStatus, formatMessageStatusClass } from "../../../../utils/utils";
import { ButtonAdd, ButtonDelete } from "../../../common/Button";
import { CustomPagination } from "../../../common/CustomPagination";
import ChangeStatusMessageModal from "../../modals/admin/ChangeStatusMessageModal";
import DetailsMessageModal from "../../modals/admin/DetailMessageModal";
import { AdminCard } from "../AdminLayouts/AdminLayout";

const Messages = () => {
	const [item, setItem] = useState<IContact>();
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const { data, isLoading } = useGetContactMessagesQuery({ limit: perPage, page });
	return (
		<div className="messages__page">
			<h1>Messages</h1>
			<AdminCard>
				<div className="faq-cards-container">
					{!isLoading && (
						<>
							{data?.results?.map((item) => (
								<MessageItem key={item?.slug} item={item} setItem={setItem} />
							))}
							{data?.count && data?.count > perPage && (
								<CustomPagination
									nbPages={data?.count}
									page={page}
									onChange={(page, perPage) => {
										setPerPage(perPage);
										setPage(page);
									}}
									perPage={perPage}
								/>
							)}
						</>
					)}

					{isLoading && [...Array(5)].map((item, i) => <MessageItemSkeleton key={i} />)}
				</div>
			</AdminCard>
			<DetailsMessageModal item={item} />
			<ChangeStatusMessageModal item={item} />
		</div>
	);
};

export function MessageItem(props: { item: IContact; setItem: (item: IContact) => void }) {
	const { item, setItem } = props;
	return (
		<div className="message__card shadow px-3 py-4 mb-3" style={{ borderRadius: "10px" }}>
			<div className="row align-items-center">
				<div
					role="button"
					data-bs-toggle="modal"
					data-bs-target="#DetailsMessageModal"
					className="col-md-7"
					onClick={() => setItem(item)}
				>
					<h3 className="admin-card-title mb-2" style={{ fontWeight: 400 }}>
						{item?.subject.slice(0, 70)}
					</h3>
					<h6
						style={{
							fontWeight: 400,
							lineHeight: "18px",
							fontSize: 14,
							color: "#9FA2B4",
						}}
					>
						{moment(item?.created_at).format("DD/MM/YYYY à HH:MM")}
					</h6>
				</div>

				<div className="d-flex gap-3 col-md-2">
					<ButtonAdd
						label={formatMessageStatus(item?.status)}
						style={ButtonStatusStyle}
						className={`admin-btn-add ${formatMessageStatusClass(item?.status)}`}
						data-bs-toggle="modal"
						data-bs-target="#ChangeStatusMessageModal"
						onClick={() => setItem(item)}
					/>
				</div>
				<div className="col-md-3 flex-sb">
					<h6 className="author-name">{item?.name}</h6>
					<DeleteContactMessage item={item} />
				</div>
			</div>
		</div>
	);
}
export function DeleteContactMessage({ item }: { item: IContact }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteContactMutation();
	const onDelete = useDelete<IContact>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "ce message ?",
		successMessage: "Message supprimé",
	});
	return <ButtonDelete onClick={onDelete} />;
}

export function MessageItemSkeleton() {
	return (
		<div className="mb-3">
			<div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
				<div className="row w-100 align-items-center">
					<div className="col-md-7">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
					<div className="col-md-2">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
					<div className="col-md-3">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Messages;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";

import { VendeurFormData, IVendeur, Vendeur } from "./vendeur.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


export const VendeurApi = createApi({
	reducerPath: "vendeurAPi",
	tagTypes: ["vendeur", "vendeur_by_slug", "users_deleted"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),

	endpoints: (builder) => ({
		getVendeur: builder.query<PaginationResults<IVendeur>, TypeQuery>({
			query: (query) => QueryUrl(`user/vendeurs/`, query),
			providesTags: ["vendeur"],
		}),
		getTrashSeller: builder.query<PaginationResults<IVendeur>, TypeQuery>({
			query: (query) => QueryUrl(`users_deleted/`, query),
			providesTags: ["users_deleted"],
		}),
		getVendeurBySlug: builder.query<IVendeur, any>({
			query: (slug) => `user/vendeurs/${slug}/`,
			providesTags: ["vendeur_by_slug"],
		}),
		addOrUpdateVendeur: builder.mutation<
			IVendeur,
			{ slug?: string; data: VendeurFormData | FormData }
		>({
			invalidatesTags: ["vendeur"],

			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `/users/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `users/`,
					method: "POST",
					body: data,
				};
			},
		}),
		addVendeur: builder.mutation<IVendeur, VendeurFormData | FormData>({
			invalidatesTags: ["vendeur"],
			query: (data) => {
				return {
					url: `user/vendeurs/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editVendeur: builder.mutation<
			IVendeur,
			{ slug?: string; data: VendeurFormData | FormData | any }
		>({
			invalidatesTags: ["vendeur", "vendeur_by_slug"],
			query: ({ slug, data }) => {
				return {
					url: `users/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteVendeur: builder.mutation<
			IVendeur,
			{ slug: string; data: VendeurFormData | FormData | any }
		>({
			query: ({ slug, data }) => ({
				url: `/user/${slug}/suppression/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["vendeur", "users_deleted"],
		}),
		restaurVendeur: builder.mutation<IVendeur, { deletion_id: string; email?: string }>({
			query: ({ deletion_id, email }) => {
				if (email) {
					return {
						url: `/reactive_user/${deletion_id}/`,
						method: "PUT",
						body: { email },
					};
				}
				return {
					url: `/reactive_user/${deletion_id}/`,
					method: "GET",
				};
			},
			invalidatesTags: ["vendeur", "users_deleted"],
		}),
		findVendeursBySlug: builder.query<Vendeur, string>({
			query: (slug) => `users/${slug}`,
			providesTags: ["vendeur"],

		}),
	}),
});

export const {
	useGetVendeurQuery,
	useGetVendeurBySlugQuery,
	useEditVendeurMutation,
	useLazyGetVendeurQuery,
	useAddOrUpdateVendeurMutation,
	useDeleteVendeurMutation,
	useGetTrashSellerQuery,
	useRestaurVendeurMutation,
	useAddVendeurMutation,
	useLazyFindVendeursBySlugQuery,

} = VendeurApi;

export function useVendeurFromLocation(): [Vendeur, boolean, string, (id: string) => void] {
	const itemState = useLocationState<Vendeur | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyFindVendeursBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [findBySlug, itemState, slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError, itemState, navigate]);
	useEffect(() => {
		if (data) {
			// console.log("data",data)
			setItem(data);
		}
	}, [data]);

	return [item as Vendeur, isLoading, slug as string, findBySlug];
}

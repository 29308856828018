import { ItokenStatus } from "../../../../utils/api/marketplace/marketplace.type";
import { IDiffusedPepite } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { BtnPrimary } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import { IconShare } from "../../../shared/Icons";
import { RadioDiffusionPlatforme } from "../AddPepite/Steps/Diffusion";
import { SmallPepiteCard } from "../Stock/PepiteCard";
import { useStopDiffusion } from "./useForm/useDiffusion";

const StopModalDiffusionPepite = (props: {
	item?: IDiffusedPepite;
	tokenStatus?: ItokenStatus;
	marketplace?: string;
}) => {
	const { item, tokenStatus } = props;
	const { onSubmit, checked, handleChecked, errors } = useStopDiffusion(item);
	console.log({ tokenStatus });
	return (
		<div
			className="modal fade"
			id="StopModalDiffusionPepite"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="StopModalDiffusionPepiteLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<form onSubmit={onSubmit}>
							<div className="modal__header">
								<div className="flex-c mb-3 icon__share">
									<IconShare />
								</div>
								<h2 className="modal__title">
									Arrêter la diffusion de cette pépite
								</h2>
							</div>
							<div className="modal__body mt-3">
								<SmallPepiteCard pepite={item?.produit} />
							</div>
							<div>
								<RadioDiffusionPlatforme
									checked={checked}
									handleChecked={handleChecked}
									title="Sélectionnez vos marketplaces"
									imgStyle={true}
									id="diffusion_simple"
									tokenStatus={tokenStatus}
								/>
								<FormError error={errors?.marketplace} />
							</div>
							<div className="modal__footer flex-end gap-5 mt-5">
								{/* <button
									className="logout__btn common_style px-3 py-2"
									style={{
										color: Color.dark,
										fontSize: "16px",
									}}
								>
									<span></span>
								</button> */}
								<BtnPrimary label="Arrêter" isLoading={false} mode="Secondary" />
								<button
									type="button"
									className="fashion__btn"
									data-bs-dismiss="modal"
									style={{ color: Color.red }}
									// onClick={cleannerChecked}
								>
									Annuler
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StopModalDiffusionPepite;

import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
	useGetTrashSellerQuery,
	useRestaurVendeurMutation,
} from "../../../../../utils/api/vendeur/vendeur.api";
import TableSkeleton from "../../../../common/TableSkeleton";
import { CustomPagination } from "../../../../common/CustomPagination";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { IUser } from "../../../../../utils/api/user/user.type";
import { useNavigate } from "react-router-dom";

function SellersTrashTable({ word }: { word: string }) {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { data, isLoading } = useGetTrashSellerQuery({
		limit: perPage,
		page,
		word,
	});
	console.log({ data });

	const boutiqueFormatter: any = (cell: any, row: any) => {
		return <div className="gap-3 d-flex">{row?.boutique[0]?.nom_boutique}</div>;
	};

	const nomFormatter: any = (cell: any, row: any) => {
		return (
			<div className="gap-3 d-flex">
				{row.prenom} {row.nom}
			</div>
		);
	};

	const villeFormatter: any = (cell: any, row: any) => {
		return <div className="gap-3 d-flex">{row?.boutique[0]?.ville}</div>;
	};

	const actionFormatter: any = (cell: any, row: IUser) => {
		return (
			<div className="gap-3 d-flex px-2">
				<RestaureSeller row={row} />
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Prénom & Nom",
			formatter: (cell: any, row: any) => nomFormatter(cell, row),
		},
		{
			dataField: "boutique",
			text: "Boutique",
			style: { textTransform: "uppercase" },
			formatter: (cell: any, row: any) => boutiqueFormatter(cell, row),
		},
		{
			dataField: "adresse",
			text: "Ville",
			formatter: (cell: any, row: any) => villeFormatter(cell, row),
		},
		{
			dataField: "actions",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "80px", whiteSpace: "normal" };
			},
		},
	];

	return (
		<>
			{isLoading && (
				<TableSkeleton
					headers={["Prenom & Nom", "Boutique", "Stock", "Ville", "Actions"]}
				/>
			)}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive
						striped={false}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							word
								? "Aucun vendeur ne correspond à votre recherche"
								: "Aucune donnée disponible"
						}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
		</>
	);
}

function RestaureSeller({ row }: { row: IUser }) {
	const [restaure, { isError, error, isSuccess }] = useRestaurVendeurMutation();
	const navigate = useNavigate();

	const onRestaure = () => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir restaurer le compte de ce vendeur?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.primary,
			confirmButtonColor: Color.primary,
			preConfirm: () => {
				return restaure({ deletion_id: row.email });
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.value?.data) {
			}
		});
	};
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Le compte du vendeur a été restauré  avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				navigate("/admin/vendeurs");
			});
		}
	}, [isSuccess]);
	useEffect(() => {
		if (isError) {
			const err = error as any;
			console.log({ err });
			Swal.fire({
				icon: "error",
				title: err?.data?.message ?? `Une erreur de status ${err?.status} est survenue !`,
				showConfirmButton: true,
				showCancelButton: true,
			}).then(() => {
				Swal.fire({
					title: `Restaurer le compte`,
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
					cancelButtonText: "Non",
					showLoaderOnConfirm: true,
					iconColor: Color.primary,
					confirmButtonColor: Color.primary,
					input: "text",
					inputLabel: "Entrez la nouvelle adresse email",
					inputAttributes: {
						autocapitalize: "off",
					},
					preConfirm: (inputValue) => {
						if (inputValue) {
							return restaure({ deletion_id: row.email, email: inputValue });
						} else {
							Swal.showValidationMessage(
								`le mot de passe est obligatoire pour la suppression`
							);
						}
					},
					allowOutsideClick: () => !Swal.isLoading(),
				});
			});
		}
	}, [isError]);
	return (
		<button className="admin-btn-add py-1 px-2" onClick={onRestaure}>
			Restaurer
		</button>
	);
}

export default SellersTrashTable;

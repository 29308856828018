import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { IVendeur, VendeurFormData } from "../../../../../utils/api/vendeur/vendeur.type";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
	useAddVendeurMutation,
	useEditVendeurMutation,
} from "../../../../../utils/api/vendeur/vendeur.api";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { useNavigate } from "react-router-dom";

function UseCrudVendeur(user?: IVendeur) {
	const navigate = useNavigate();

	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		prenom: yup.string().required().label("Prenom"),
		telephone: yup.string().required().label("Téléphone"),
		email: yup.string().email().required().label("Email"),
		nom_boutique: yup.string().required().label("Nom de la boutique"),
		numero_siret: yup
			.number()
			.required()
			.label("Numéro de SIREN")
			.typeError("Numéro de SIREN est un champ obligatoire"),
		code_postal: yup.string().required().label("Code Postal"),
		adresse: yup.string().required().label("Adresse"),
		ville: yup.string().required().label("Ville"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<VendeurFormData>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();
	const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
		useAddVendeurMutation();
	const { user: authUser } = useAppSelector((s) => s.user);
	useEffect(() => {
		if (isSuccess) {
			console.log("data", data);
			if (authUser?.id === data?.id) {
				dispatch(onSetUser(data));
			}
			Swal.fire({
				icon: "success",
				title: !user ? "Vendeur ajouté avec succès" : "Vendeur a été modifié avec succès",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				navigate("/admin/vendeurs");
			});
		}
		const err = error as any;
		if (isError) {
			if (err?.data?.email?.includes("user with this email already exists.")) {
				Swal.fire({
					icon: "error",
					title: "Un utilisateur avec cet email exite déjà !",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Erreur de statut ${err?.status}`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (user?.id) {
			let fields: (keyof VendeurFormData)[] = ["nom", "prenom", "telephone", "email"];
			if (user?.user_type !== "vendeur") {
				fields = ["nom", "prenom", "telephone", "email"];
			}
			for (let field of fields) {
				register(field);
				// setValue(field, user[field]);
			}
		}
	}, [user]);

	const onSubmit = (data: VendeurFormData) => {
		console.log("data", data);
		addOrUpdateUser(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default UseCrudVendeur;
export function useToggleArchiveAdmin(user: IVendeur) {
	const [editData] = useEditVendeurMutation();
	const onArchive = async () => {
		let data: VendeurFormData = {
			is_active: user.is_archive,
			is_archive: !user.is_archive,
		};
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir  ${
				user?.is_archive === true ? "désarchiver" : "archiver"
			} cet vendeur ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.success,
			confirmButtonColor: Color.success,
			preConfirm: () => {
				return editData({ slug: user.slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Vendeur ${
						result?.value?.data?.is_archive ? "archivé " : "désarchivé"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	return onArchive;
}

import React, { useState } from "react";
import {
	useDeleteCouleurMutation,
	useGetCouleurQuery,
} from "../../../../../utils/api/couleur/couleur.api";
import { ICouleur } from "../../../../../utils/api/couleur/couleur.type";
import { useDelete } from "../../../../../utils/helpers";
import { useModal } from "../../../../../utils/utils";
import { IconDelete, IconEdit } from "../../../../shared/Icons";
import AddCouleurModal from "../../../modals/admin/AddCouleurModal";
import { CaracteristiqueCard } from "../Caracteristiques";

const Couleur = () => {
	const { data, isLoading } = useGetCouleurQuery();
	const [item, setItem] = useState<ICouleur>();
	const { isShowModal, toggle } = useModal();
	const openModal = () => {
		setItem(undefined);
		toggle();
	};
	return (
		<CaracteristiqueCard>
			<div className="flex-m-sb">
				<h5 className="carateritique-card-title">Couleur du vêtement</h5>
				<button className="btn-caracteristiques-add" onClick={openModal}>
					Ajouter une couleur
				</button>
			</div>
			<div className="d-flex flex-wrap gap-3 mt-5">
				{!isLoading && data && data?.results?.length > 0
					? data?.results?.map((item) => (
							<div
								className="item-caracteristique d-flex align-items-center gap-3"
								key={item?.slug}
							>
								<h6>{item?.nom}</h6>
								<button
									onClick={() => {
										setItem(item);
										toggle();
									}}
								>
									<IconEdit />
								</button>
								<DeleteCouleur item={item} />
							</div>
					  ))
					: null}
			</div>
			<AddCouleurModal item={item} isOpen={isShowModal} closeModal={toggle} />
		</CaracteristiqueCard>
	);
};

export function DeleteCouleur({ item }: { item: ICouleur }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteCouleurMutation();
	const onDelete = useDelete<ICouleur>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette couleur ?",
		successMessage: "Couleur supprimée",
	});
	return (
		<button onClick={onDelete}>
			<IconDelete />
		</button>
	);
}

export default Couleur;

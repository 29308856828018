import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { PepiteFormData, SelectOptionType } from "../../../../../utils/api/pepite/pepite.type";
import {
	useAddOrUpdatePepiteMutation,
	useDeletePepiteImageMutation,
	usePepiteFromLocation,
} from "../../../../../utils/api/pepite/pepite.api";
import {
	cleannerError,
	convertDimensionsUnitForEbay,
	convertWeightUnitForEbay,
	getArrayImagesLength,
} from "../../../../../utils/utils";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";
import { UserType } from "../../../../../utils/api/user/user.type";
import { IBoutique } from "../../../../../utils/api/boutique/boutique.type";

const initialData = {
	slug: "",
	nom: "",
	description: "",
	genre: "",
	taille: undefined,
	couleur: undefined,
	marque: "",
	etat: undefined,
	prix: undefined,
	en_ligne: false,
	categorie: undefined,
	sous_categorie: undefined,
	images: undefined,
	step: 1,
};

function useEditPepiteForm(navigation: any) {
	const validationSchema = yup.object().shape({
		step: yup.number().default(1),
		nom: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Titre"),
		}),
		description: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Description"),
		}),
		genre: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Genre"),
		}),
		taille: yup.array().when("step", {
			is: 2,
			then: yup.array().required().label("Taille"),
		}),
		couleur: yup.array().when("step", {
			is: 2,
			then: yup.array().required().label("Couleur"),
		}),
		categorie: yup
			.number()
			.when("step", {
				is: 2,
				then: yup
					.number()
					.required()
					.label("Catégorie")
					.typeError("Catégorie est un champ obligatoire"),
			})
			.typeError("Catégorie est un champ obligatoire"),
		// sous_categorie: yup.number().when("step", {
		// 	is: 2,
		// 	then: yup
		// 		.number()
		// 		.required()
		// 		.label("Sous Catégorie")
		// 		.typeError("Sous Catégorie est un champ obligatoire"),
		// }),
		etat: yup.number().when("step", {
			is: 2,
			then: yup
				.number()
				.required()
				.label("État du vêtement")
				.typeError("État du vêtement est un champ obligatoire"),
		}),
		prix: yup.string().when("step", {
			is: 4,
			then: yup.string().required().label("Prix de vente"),
		}),
		online: yup.boolean().when("step", {
			is: 5,
			then: yup
				.boolean()
				.required("Ce champ est obligatoire")
				.typeError("Ce champ est obligatoire"),
		}),
		marketplace: yup.string().when("step", {
			is: 5,
			then: yup.string().when("online", {
				is: true,
				then: yup.string().required("Veuillez choisir au moins une marketplace"),
			}),
		}),
		// marketplace: yup.array().when("step", {
		// 	is: 5,
		// 	then: yup.array().when("online", {
		// 		is: true,
		// 		then: yup.array().required().min(1, "Veuillez choisir au moins une marketplace"),
		// 	}),
		// }),
	});
	const [item] = usePepiteFromLocation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
		control,
	} = useForm<PepiteFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: { ...initialData, online: item?.en_ligne },
	});

	const navigate = useNavigate();
	const [images, setImages] = useState<(File | undefined)[]>();
	const [photo, setPhoto] = useState<File>();
	const [formValues, setFormValues] = useState<PepiteFormData>();
	const [addOrUpdatePepite, { isLoading }] = useAddOrUpdatePepiteMutation();
	const [deletePepiteImage] = useDeletePepiteImageMutation();
	const { user } = useAppSelector((s) => s.user);


	const onSelectImages = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files?.length) {
			const photos = Object.keys(e.currentTarget.files)?.map((key) => {
				if (e.currentTarget.files) {
					return e.currentTarget.files[parseInt(key)];
				}
				return undefined;
			});
			if (images) {
				let arrayOfImages = [...images, ...photos];
				setImages(arrayOfImages);
				setValue("images", arrayOfImages);
			} else {
				setImages(photos);
				setValue("images", photos);
			}
		}
	};

	const onSelectPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			setPhoto(e.currentTarget.files[0]);
			setValue("photo_principale", e.currentTarget.files[0]);
		}
	};

	const onDeleteSelectedImageItem = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		index: number
	) => {
		e.preventDefault();

		if (images && images?.length > 0) {
			console.log({ index });
			const newArrayOfImages = [...images];
			newArrayOfImages.splice(index, 1);
			setImages(newArrayOfImages);
			setValue("images", newArrayOfImages);
		}
	};

	const onDeleteImage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, slug: string) => {
		e.preventDefault();
		deletePepiteImage(slug);
	};



	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("nom", item?.nom);
			setValue("description", item?.description);
			setValue("genre", item?.genre);
			setValue("who_made", item?.who_made);
			setValue("when_made", item?.when_made);
			setValue("weight", item?.weight);
			setValue("length", item?.length);
			setValue("width", item?.width);
			setValue("height", item?.height);
			setValue("dimensions_unit_etsy", item?.dimensions_unit_etsy);
			setValue("dimensions_unit_ebay", item?.dimensions_unit_ebay);
			setValue("weight_unit_etsy", item?.weight_unit_etsy);
			setValue("weight_unit_ebay", item?.weight_unit_ebay);
			setValue("should_auto_renew", item?.should_auto_renew);
			setValue("type", item?.type);
			setValue("tags", item?.tags?.tags);
			setValue("style", item?.style?.style);
			setValue("materials", item?.materials?.materials);
			setValue(
				"taille",
				item?.taille?.map((elem) => elem?.id)
			);
			setValue(
				"couleur",
				item?.couleur?.map((elem) => elem?.id)
			);
			setValue("marque", item?.marque);
			setValue("prix", item?.prix);
			setValue("en_ligne", item?.en_ligne);
			setValue("boutique", item?.boutique?.id);
			setTimeout(() => {
				setValue("categorie", item?.categorie?.id);
				setValue("etat", item?.etat?.id);
				if (item?.sous_categorie?.id) {
					setValue("sous_categorie", item?.sous_categorie?.id);
				}
			}, 3000);
		}
	}, [item]);

	const onChangeColors = (selected: SelectOptionType[]) => {
		setValue(
			"couleur",
			selected?.map((item) => item?.value)
		);
	};
	const onChangeSize = (selected: SelectOptionType[]) => {
		setValue(
			"taille",
			selected?.map((item) => item?.value)
		);
	};

	const getSelectedBoutiqueItem = (elem: IBoutique) => {
		setValue("boutique", elem?.id);
	};

	const onSubmit = async (data: PepiteFormData) => {
		// console.log(data);
		switch (data?.step) {
			case 1:
				// console.log(data);
				navigation.next();
				break;
			case 2:
				navigation.next();
				// console.log(data);
				break;
			case 3:
				// const totalImages =
				// 	getArrayImagesLength(item?.images) + getArrayImagesLength(data?.images);
				// if (totalImages < 3) {
				// 	return setError("images", { message: "Au minimum 3 photos" });
				// }
				// if (totalImages > 7) {
				// 	return setError("images", { message: "7 photos au maximum" });
				// }
				setFormValues({ ...data });
				navigation.next();
				// console.log(data);
				break;
			case 4:
				navigation.next();
				// console.log(data);
				setFormValues({ ...data });
				break;
			case 5:
				navigation.next();
				// console.log(data);
				setFormValues({ ...data });
				break;
			default:
				const fd = new FormData();
				if (user?.boutique && user?.user_type === UserType.vendeur) {
					fd.append("boutique", user?.boutique[0]?.id);
				}
				const { tags, materials, style, ...rest } = data;
				if (data?.weight_unit_etsy) {
					fd.append("weight_unit_ebay", convertWeightUnitForEbay(data?.weight_unit_etsy));
				}
				if (data?.dimensions_unit_etsy) {
					console.log(convertDimensionsUnitForEbay(data?.dimensions_unit_etsy));
					fd.append(
						"dimensions_unit_ebay",
						convertDimensionsUnitForEbay(data?.dimensions_unit_etsy)
					);
				}
				if (tags) {
					fd.append("tags", JSON.stringify(tags));
				}
				if (materials) {
					fd.append("materials", JSON.stringify(materials));
				}
				if (style) {
					fd.append("style", JSON.stringify(style));
				}
				for (let key of Object.keys(rest)) {
					const value = data[key];
					if (Array.isArray(value) && value?.length) {
						for (let val of value) {
							fd.append(key, val);
						}
					} else if (value !== undefined) {
						fd.append(key, value);
					}
				}
				const res = await addOrUpdatePepite({ slug: item?.slug, data: fd });
				if ("data" in res) {
					const response = res.data as any;
					Swal.fire({
						icon: "success",
						title: "Modifications mise à jour !",
						text: `Les modifications faites sur votre pépite viennent d’être mise à jour ${
							response?.diffusion_status === 201 && response?.data?.id
								? "sur les marketplaces. Bonne vente !"
								: data?.online
								? "mais n'a pas été diffusée par erreur d'authentification, veuillez le faire ultérieurement"
								: ""
						}`,
						iconColor: Color.success,
						showConfirmButton: true,
						confirmButtonText: "Fermer",
						customClass: {
							confirmButton: "btn-swal-close mx-4",
							container: "validation__step",
							title: "swal__success__title",
						},
					}).then(() => {
						if (user?.user_type !== UserType.vendeur) {
							return navigate("/admin/pepites");
						}
						navigate("/user/mon-stock");
					});
				} else if ("error" in res) {
					const err = res.error as any;
					console.log(err);
					Swal.fire({
						icon: "error",
						title: err?.data?.message
							? err?.data?.message
							: "Une erreur a survenu lors de la modification",
						showConfirmButton: true,
						confirmButtonColor: Color.primary,
						timer: 5000,
					});
				}
				break;
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		images,
		onSelectImages,
		item,
		Controller,
		control,
		onChangeColors,
		onChangeSize,
		getSelectedBoutiqueItem,
		formValues,
		user,
		onDeleteSelectedImageItem,
		onDeleteImage,
		photo,
		onSelectPhoto,
	};
}

export default useEditPepiteForm;

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useResetUserPasswordMutation } from "../../../../utils/api/auth/auth.api";
import {
	ForgetPasswordFormData,
	ResetPasswordFormData,
} from "../../../../utils/api/auth/auth.type";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/utils";
import { validatePassword } from "./useSignupForm";
export function UseResetPasswordForm() {
	const [resetPassword, { error, isSuccess, isLoading }] = useResetUserPasswordMutation();

	const state = useLocation().state as ForgetPasswordFormData;

	const navigate = useNavigate();

	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup.string().email().required().label("Email"),
				code: yup
					.number()
					.required()
					.label("Code")
					.typeError("Code est un champ obligatoire"),
				new_password: validatePassword(yup),
				new_password_confirm: yup
					.string()
					.oneOf([yup.ref("new_password"), null], "Les mots de passe ne correspond pas"),
			}),
		[]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		setError,
	} = useForm<ResetPasswordFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (state?.email) {
			setValue("email", state.email);
		} else {
			setTimeout(() => navigate("/forget-password"), 1);
		}
	}, [state?.email]);

	useEffect(() => {
		if (error) {
			const err = error as any;
			const { message = "Une erreur a survenue" } = err.data || {};
			setError("code", { message: message });
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Mot de passe modifié avec succès !",
			});
			navigate("/se-connecter");
		}
	}, [isSuccess]);

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);
	return {
		register,
		errors,
		onSubmit: handleSubmit(resetPassword),
		clearErrors,
		isLoading,
	};
}

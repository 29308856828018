import React, { useState } from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";
import {
	useDeleteMarketPlaceMutation,
	useGetMarketPlaceQuery,
} from "../../../../utils/api/marketplace/marketplace.api";
import { IMarketPlace } from "../../../../utils/api/marketplace/marketplace.type";
import { useDelete } from "../../../../utils/helpers";
import { getImage, useModal } from "../../../../utils/utils";
import { ButtonAdd, ButtonDelete, ButtonEdit } from "../../../common/Button";
import AddMarketPlaceModal from "../../modals/admin/AddMarketPlaceModal";
import { AdminCard } from "../AdminLayouts/AdminLayout";

const Marketplace = () => {
	const { data, isLoading } = useGetMarketPlaceQuery();
	const [item, setItem] = useState<IMarketPlace>();
	const { isShowModal, toggle } = useModal();
	const openEditModal = (item: IMarketPlace) => {
		setItem(item);
		toggle();
	};
	const openAddModal = () => {
		setItem(undefined);
		toggle();
	};
	return (
		<div className="marketplace__page">
			<h1>Marketplaces</h1>

			<AdminCard>
				<div className="flex-end mb-5">
					<ButtonAdd label="Ajouter une marketplace" onClick={openAddModal} />
				</div>
				<div className="row">
					{!isLoading ? (
						data?.map((item) => (
							<div className="col-md-3 col-sm-6 px-3 mb-3" key={item.id}>
								<div className="shadow pb-2 pt-5 px-2" style={{ borderRadius: 6 }}>
									<div className="market-img-container flex-c">
										<img
											src={getImage(item.logo)}
											alt={item?.slug}
											style={{ height: "40px" }}
										/>
									</div>
									<div className="d-flex gap-2 justify-content-end mt-3">
										<ButtonEdit onClick={() => openEditModal(item)} />
										<DeleteMarketPlace item={item} />
									</div>
								</div>
							</div>
						))
					) : (
						<>
							{[...Array(4)]?.map((item, index) => (
								<div className="col-md-3 col-sm-6 px-3 mb-3" key={index}>
									<VolkenoSkeleton variant="rect" width="100%" height="130px" />
								</div>
							))}
						</>
					)}
				</div>
			</AdminCard>
			<AddMarketPlaceModal
				item={item}
				isOpen={isShowModal}
				closeModal={toggle}
				setItem={setItem}
			/>
		</div>
	);
};
export function DeleteMarketPlace({ item }: { item: IMarketPlace }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteMarketPlaceMutation();
	const onDelete = useDelete<IMarketPlace>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette marketplace ?",
		successMessage: "Marketplace supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}
export default Marketplace;

import React, { useState } from "react";
import { useDeleteEtatMutation, useGetEtatQuery } from "../../../../../utils/api/etat/etat.api";
import { IEtat } from "../../../../../utils/api/etat/etat.type";
import { useDelete } from "../../../../../utils/helpers";
import { useModal } from "../../../../../utils/utils";
import { IconDelete, IconEdit } from "../../../../shared/Icons";
import AddEtatModal from "../../../modals/admin/AddEtatModal";
import { CaracteristiqueCard } from "../Caracteristiques";

const Etat = () => {
	const { data, isLoading } = useGetEtatQuery();
	const [item, setItem] = useState<IEtat>();
	const { isShowModal, toggle } = useModal();
	const openModal = () => {
		setItem(undefined);
		toggle();
	};
	return (
		<CaracteristiqueCard>
			<div className="flex-m-sb">
				<h5 className="carateritique-card-title">État du vêtement</h5>
				<button className="btn-caracteristiques-add" onClick={openModal}>
					Ajouter un état
				</button>
			</div>
			<div className="d-flex flex-wrap gap-3 mt-5">
				{!isLoading && data && data?.results?.length > 0
					? data?.results?.map((item) => (
							<div
								className="item-caracteristique d-flex align-items-center gap-3"
								key={item?.slug}
							>
								<h6>{item?.nom}</h6>
								<button
									onClick={() => {
										setItem(item);
										toggle();
									}}
								>
									<IconEdit />
								</button>
								<DeleteEtat item={item} />
							</div>
					  ))
					: null}
			</div>
			<AddEtatModal item={item} isOpen={isShowModal} closeModal={toggle} />
		</CaracteristiqueCard>
	);
};

export function DeleteEtat({ item }: { item: IEtat }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteEtatMutation();
	const onDelete = useDelete<IEtat>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet état ?",
		successMessage: "Etat supprimé",
	});
	return (
		<button onClick={onDelete}>
			<IconDelete />
		</button>
	);
}

export default Etat;

import { Spinner } from "../../../common/Button";
import modeleXls from "../../../../assets/Files/TFT_ETSY_EBAY_PRODUCTS.xlsx";
// import modeleCsv from "../../../../assets/Files/ETSY_AND_EBAY-Grid_view.csv";
import { useImportFile } from "./useImportFile";
import { Color } from "../../../../utils/theme";

const ModaImportFilePepite = () => {
	const {
		onChangeFile,
		onSubmit,
		isLoading,
		// onImportCsvFile,
		// isLoadCsv,
	} = useImportFile();
	return (
		<>
			<div
				className="modal fade"
				id="ModaImportFilePepite"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				aria-labelledby="ModaImportFilePepiteLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content fas__modal  px-3 py-4">
						<div className="container-fluid mx-auto">
							<form onSubmit={onSubmit}>
								<label htmlFor="importFiles" className="mb-2">
									Choisir un fichier
								</label>
								<div className="">
									{/* <label
										htmlFor="csv"
										className="fashion__btn btn__add px-3 py-3 mb-3 btn-import"
									>
										{!isLoadCsv ? (
											<>
												CSV
												<input
													type="file"
													id="csv"
													className="input-hidden-file"
													accept=".csv"
													onChange={onImportCsvFile}
													style={{ visibility: "hidden" }}
												/>
											</>
										) : (
											<Spinner mode="text-dark" />
										)}
									</label> */}
									<label
										htmlFor="xls"
										className="fashion__btn btn__add px-3 py-3 btn-import mb-3"
									>
										{!isLoading ? (
											<>
												Excel
												<input
													type="file"
													id="xls"
													className="input-hidden-file"
													accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
													onChange={onChangeFile}
												/>{" "}
											</>
										) : (
											<Spinner mode="text-dark" />
										)}
									</label>
								</div>
								{/* <div className="mb-3">
									<a
										className="fashion__btn btn__add px-3 py-3 btn-import"
										role="button"
										target="_blank"
										href="https://airtable.com/appE1I2fhCFUNXkAO/tblajMgciwcH6N3gu/viwJNKbMXHrI4vos7?blocks=show"
									>
										Voir le modèle
									</a>
								</div> */}
								<div className="mb-3">
									<a
										className="fashion__btn btn__add px-3 py-3 btn-import"
										role="button"
										target="_blank"
										href={modeleXls}
									>
										Télécharger le modèle
									</a>
								</div>
								<div className="flex-end mt-5 gap-2">
									<button
										type="button"
										className="fashion__btn"
										data-bs-dismiss="modal"
										style={{ color: Color.primary }}
									>
										Fermer
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModaImportFilePepite;

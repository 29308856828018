import { useState } from "react";
import { ButtonAdd } from "../../../common/Button";
import { InputFilter } from "../../../common/Input";
import SellersTable from "../../tables/admin/SellersTable/SellersTable";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import { Link } from "react-router-dom";
import { Color } from "../../../../utils/theme";

const Sellers = () => {
	const [search, setSearch] = useState<string>("");
	return (
		<div className="sellers__page">
			<h1>Vendeurs</h1>
			<AdminCard>
				<div className="d-flex justify-content-between my-4">
					<div className="filter-container">
						<label htmlFor="" className="me-2">
							Filtrer
						</label>
						<InputFilter
							label="Nom du vendeur"
							onChange={(e) => setSearch(e.currentTarget.value)}
						/>
					</div>
					<div className="d-flex align-items-center gap-3">
						<Link
							to="/admin/corbeille"
							style={{ color: Color.dark, textDecoration: "underline" }}
						>
							Corbeille
						</Link>
						<ButtonAdd
							path="/admin/vendeurs/ajouter-un-vendeur"
							label="Ajouter un vendeur"
						/>
					</div>
				</div>
				<SellersTable word={search} />
			</AdminCard>
		</div>
	);
};

export default Sellers;

import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ButtonDelete, ButtonEditLink, ButtonViewLink } from "../../../../common/Button";
import { CustomPagination } from "../../../../common/CustomPagination";
import {
	useDeletePepiteMutation,
	useGetPepiteQuery,
} from "../../../../../utils/api/pepite/pepite.api";
import TableSkeleton from "../../../../common/TableSkeleton";
import { IMarketPlace } from "../../../../../utils/api/marketplace/marketplace.type";
import { IPepite } from "../../../../../utils/api/pepite/pepite.type";
import { getImage } from "../../../../../utils/utils";
import { useDelete } from "../../../../../utils/helpers";
import { AlertInfo } from "../../../../common/Alert";
import { IBoutique } from "../../../../../utils/api/boutique/boutique.type";

interface PepiteTableProps {
	pepiteName: string | null;
	status: string | null;
	vendeur: string | null;
}

function PepitesTable({ pepiteName, status, vendeur }: PepiteTableProps) {
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);

	const { data, isLoading } = useGetPepiteQuery({
		page,
		limit: perPage,
		nom: pepiteName,
		en_ligne: status,
		vendeur: vendeur,
	});

	// console.log({ data });
	const actionFormatter: any = (cell: any, row: IPepite) => {
		return (
			<div className="gap-3 d-flex">
				<ButtonViewLink to={`/admin/pepites/${row?.slug}`} state={row} />
				<ButtonEditLink to={`/admin/pepites/${row?.slug}/modifier`} state={row} />
				<DeletePepite item={row} />
			</div>
		);
	};
	const plateformFormatter: any = (cell: IMarketPlace[], row: IPepite) => {
		return (
			<div className="flex-m gap-2">
				{cell?.length > 0 ? (
					cell?.map((item, index) => (
						<div className="img__container" key={item?.slug}>
							<img
								src={getImage(item?.logo)}
								alt={item?.slug}
								className="logo-brodcast"
							/>
							{index < cell?.length - 1 && <span className="ms-2">_</span>}
						</div>
					))
				) : (
					<span className="ms-2">__</span>
				)}
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Produit",
		},
		{
			dataField: "boutique",
			text: "Vendeur",
			formatter: (cell: IBoutique, row: any) =>
				cell?.vendeur?.prenom + " " + cell?.vendeur.nom,
		},

		{
			dataField: "marketplace",
			text: "Diffusé",
			formatter: (cell: IMarketPlace[], row: any) => plateformFormatter(cell, row),
		},
		{
			dataField: "en_ligne",
			text: "Statut",
			formatter: (cell: boolean) => (cell ? "En Ligne" : "Ébauche"),
		},
		{
			dataField: "actions",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "80px", whiteSpace: "normal" };
			},
		},
	];

	return (
		<>
			{isLoading && (
				<TableSkeleton headers={["Nom", "Vendeur", "Diffusé", "Statut", "Actions"]} />
			)}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={false}
						condensed={false}
						responsive
						striped={false}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={<AlertInfo message="Aucune pépite trouvée" />}
					/>
					{data?.count && data?.count > perPage && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, perPage) => {
								setPerPage(perPage);
								setPage(page);
							}}
							perPage={perPage}
						/>
					)}
				</>
			)}
		</>
	);
}

export function DeletePepite({ item }: { item: IPepite }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeletePepiteMutation();
	const onDelete = useDelete<IPepite>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette pépite ?",
		successMessage: "Pépite supprimé",
	});
	return <ButtonDelete onClick={onDelete} />;
}
export default PepitesTable;

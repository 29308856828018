import { LogoApp } from "../Layouts/SideBar/SidebarUser";
import { ProfilDropdown } from "../Layouts/TopBar/TopBar";
import Gift from "../../../../assets/images/image.png";
import "./MonAbonnement.css";
import { AbonnementsList } from "../../admin/Abonnement/Abonnement";
import ChooseRenewAbonnementModal from "../../modals/user/ChooseRenewAbonnementModal";
import { IOffer } from "../../../../utils/api/offer/offer.type";
import { useItem } from "../../../../utils/utils";
import LogoutModal from "../../modals/user/LogoutModal";

// const tableHead = ["Fonctionnalités", "Forever free", "Essentiel", "Basic", "Avancée"];
// const tableData = [
// 	{
// 		title: "Gestionnaire de stock",
// 		forever: "-",
// 		essentiel: <IconCheck />,
// 		basic: <IconCheck />,
// 		advanced: <IconCheck />,
// 	},
// 	{
// 		title: "Nombre de pièces illimité",
// 		forever: "-",
// 		essentiel: <IconCheck />,
// 		basic: <IconCheck />,
// 		advanced: <IconCheck />,
// 	},
// 	{
// 		title: "Analyse de données",
// 		forever: <IconCheck />,
// 		essentiel: "Basique",
// 		basic: "Standard",
// 		advanced: "Avancée",
// 	},
// 	{
// 		title: "Canaux de ventes ( diffusez vos produits sur les marketpkaces)",
// 		forever: "-",
// 		essentiel: "2 Marketplaces",
// 		basic: "3 Marketplaces",
// 		advanced: "Toutes les marketplaces disponiblles",
// 	},
// 	{
// 		title: "Intégrations",
// 		forever: "Paiement à la carte",
// 		essentiel: "-",
// 		basic: "-",
// 		advanced: "-",
// 	},
// 	{
// 		title: "Utilisateur",
// 		forever: "-",
// 		essentiel: "1",
// 		basic: "2",
// 		advanced: "5",
// 	},
// 	{
// 		title: "Support client par e-mail",
// 		forever: "-",
// 		essentiel: <IconCheck />,
// 		basic: <IconCheck />,
// 		advanced: <IconCheck />,
// 	},
// 	{
// 		title: "Support client par chat",
// 		forever: "-",
// 		essentiel: "-",
// 		basic: "-",
// 		advanced: <IconCheck />,
// 	},
// 	{
// 		title: "Support client par téléphone",
// 		forever: "-",
// 		essentiel: "-",
// 		basic: "-",
// 		advanced: <IconCheck />,
// 	},
// ];

const ToutesLesOffres = () => {
	const { item, getItem } = useItem<IOffer>();

	return (
		<div className="all-offers">
			<div className="d-flex justify-content-between px-2 px-md-4 px-xl-5">
				<LogoApp />
				<ProfilDropdown />
			</div>
			<div
				className="banner mt-3"
				style={{ background: "#f2ba8c", height: "550px", width: "100%" }}
			>
				<div className="container ">
					<div className="row d-flex flex-row-reverse align-items-center pt-5">
						<div className="col-md-6 mb-4">
							<div className="img-container flex-end">
								<img src={Gift} alt="toutes-les-offres" className="banner-img" />
							</div>
						</div>
						<div className="col-md-6">
							<h1 className="banner-title">OFFRES ET PRIX</h1>
							<h2 className="banner-title mb-2">
								Diffuser vos pépites sur plusieurs marketplaces n’a jamais été aussi
								simple !
							</h2>
							{/* <p className="banner-text">
								Essayez TFT Solution gratuitement pendant 14 jours. Aucune carte de
								crédit requise.
							</p> */}
							<div className="btn-container pt-5">
								<button
									className="btn-start"
									data-bs-toggle="modal"
									data-bs-target="#PayementModal"
								>
									Démarrez votre essai gratuit
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container offers-cards-container">
				<div className="offers-cards py-1">
					<AbonnementsList getItem={getItem} />
				</div>
				{/* <div className="sans-abonnement">
					<div>
						<h3>Sans abonnement</h3>
						<div className="sans-abonnement-infos">
							<p>
								Gérez vos pépites sans engagement ! Créer votre catalogue produit
								gratuitement. Payez uniquement la diffusion de vos pièces à la carte
								sur les marketplaces.
							</p>
						</div>
					</div>
					<div className="offers-tables-containers mt-5">
						<h3>Comparez nos offres</h3>
						<div className="table-container table-responsive pt-4">
							<table className="table">
								<thead>
									<tr>
										{tableHead?.map((title, index) => (
											<th key={index}>{title}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{tableData?.map((item) => (
										<tr key={item?.title}>
											<td style={{ textAlign: "left" }}>{item?.title}</td>
											<td>{item?.forever}</td>
											<td>{item?.essentiel}</td>
											<td>{item?.basic}</td>
											<td>{item?.advanced}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<ContactForm isShowContact /> */}
			</div>
			<LogoutModal />
			<ChooseRenewAbonnementModal item={item} />
		</div>
	);
};

export default ToutesLesOffres;

import { BsChatDotsFill } from "react-icons/bs";
import { HiViewGridAdd } from "react-icons/hi";
import { IconBag, IconGrid, IconStore, IconShare } from "../../../../shared/Icons";
export const Routes = [
	{
		label: "Dashboard",
		pathname: "/user/dashboard",
		icon: <IconGrid />,
	},
	{
		label: "mon stock",
		pathname: "/user/mon-stock",
		icon: <IconStore />,
	},
	{
		label: "collections",
		pathname: "/user/mes-collections",
		icon: <HiViewGridAdd style={{ fontSize: "26px" }} />,
	},
	{
		label: "en ligne",
		pathname: "/user/diffuser",
		icon: <IconShare />,
	},
	{
		label: "Mes Ventes",
		pathname: "/user/mes-ventes",
		icon: <IconBag />,
	},
	{
		label: "Mes Messages",
		pathname: "/user/mes-messages",
		icon: <BsChatDotsFill style={{ fontSize: "18px" }} />,
	},
];

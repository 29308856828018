import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import Valider from "./Steps/Valider";
import StepperVertical from "../../../common/StepperVertical";
import Popup from "../../../common/Popup";
import { MdClose } from "react-icons/md";
import { Color } from "../../../../utils/theme";
import useEditPepiteForm from "./useForm/useEditPepiteForm";
import Description from "../AddPepite/Steps/Description";
import Caracteristiques from "../AddPepite/Steps/Caracteristiques";
import { useNavigate } from "react-router-dom";
import Prix from "../AddPepite/Steps/Prix";
import Diffusion from "../AddPepite/Steps/Diffusion";
import Photos from "../AddPepite/Steps/Photos";
import { MyStepperContext } from "../../../../utils/StepperContext";
import { isAdmin } from "../../../../routes/routerHelper";

const steps = [
	{
		id: "description",
		Component: Description,
	},
	{
		id: "caracteristiques",
		Component: Caracteristiques,
	},
	{
		id: "photos",
		Component: Photos,
	},
	{
		id: "prix",
		Component: Prix,
	},
	{
		id: "diffusion",
		Component: Diffusion,
	},
	{
		id: "valider",
		Component: Valider,
	},
];
const levels = ["Description", "Caractéristiques", "Photos", "Prix", "Diffusion", "Valider"];

const EditPepite = () => {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		setValue,
		images,
		onSelectImages,
		item,
		Controller,
		control,
		onChangeColors,
		onChangeSize,
		getSelectedBoutiqueItem,
		formValues,
		user,
		onDeleteSelectedImageItem,
		onDeleteImage,
		photo,
		onSelectPhoto,
	} = useEditPepiteForm(navigation);
	const { Component } = step;
	const props = {
		navigation,
		index,
		register,
		onSubmit,
		errors,
		isLoading,
		setValue,
		images,
		onSelectImages,
		item,
		Controller,
		control,
		onChangeColors,
		onChangeSize,
		getSelectedBoutiqueItem,
		formValues,
		user,
		onDeleteSelectedImageItem,
		onDeleteImage,
		photo,
		onSelectPhoto,
	};

	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(10, 10);
		setValue("step", index + 1);
	}, [index]);

	const onClickStep = (key: number) => {
		// if (index > key) {
		navigation.go(key);
		setValue("step", key);
		// }
	};

	return (
		<div className="add__pepite_page">
			<h1>Modifier {isAdmin(user) ? "la" : "ma"} pépite</h1>
			<div className="row">
				<div className="col-md-3 view-md d-lg-none">
					<Popup />
				</div>
				<div className="col-md-9 col-lg-12">
					<div className="mt-5 row  d-md-flex flex-row-reverse">
						<div className="col-md-3 col-xl-2">
							<div className="flex-end mb-5">
								<button
									className="logout__btn common_style px-3 py-2"
									style={{ background: "#FBEEE9", color: Color.red }}
									onClick={() => navigate(-1)}
								>
									<span>Annuler</span>
									<MdClose />
								</button>
							</div>
							<MyStepperContext.Provider value={{ onClickStep }}>
								<StepperVertical index={index} steps={levels} />
							</MyStepperContext.Provider>
						</div>
						<div className="fashion__card px-xl-3 px-1 py-3 col-xl-10 col-md-9">
							<Component {...props} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditPepite;

import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IContact } from "../../../../utils/api/contact/contact.type";
import useResponseContactForm from "../../admin/Messages/useForm/useResponseContactForm";
import { FormError } from "../../../common/Input";
import { AlertInfo } from "../../../common/Alert";
import { createMarkup, getAvatar } from "../../../../utils/utils";
import moment from "moment";

const DetailsMessageModal = ({ item }: { item?: IContact }) => {
	const { errors, onSubmit, response, handleChangeMessage, isLoading, setResponse } =
		useResponseContactForm(item);
	console.log(item?.responses);
	return (
		<div
			className="modal fade"
			id="DetailsMessageModal"
			aria-labelledby="DetailsMessageModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content py-2">
					<div className="fas-modal-header d-flex justify-content-between align-items-center px-3">
						<h3 className="fas-modal-title">Réponse à {item?.name}</h3>
						<CloseModalButton />
					</div>
					<div className="form-container px-3 pt-3">
						<form onSubmit={onSubmit} className="border-bottom pb-4">
							<div className="row border-bottom pb-2">
								<div className="col-md-12 form-group">
									<p
										className="admin-card-description "
										dangerouslySetInnerHTML={createMarkup(item?.message)}
									/>
								</div>
							</div>
							<div className="row mb-4 mt-4">
								<div className="col-12 form-group">
									<label htmlFor="desc-mision" className="admin-form-label">
										Réponse
									</label>
									<ReactQuill
										className="editor-cgu"
										onChange={handleChangeMessage}
										value={response}
									/>
									{errors?.response && <FormError error={errors?.response} />}
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<CloseModalButton text="Fermer" />
								<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
							</div>
						</form>
						<div className="row">
							<div className="col-12 mt-4">
								<h3 className="fas-modal-title mb-3">Réponses au message</h3>
								{item?.responses && item?.responses?.length > 0 ? (
									item?.responses?.map((res) => (
										<div key={res?.id} className="mb-2 border-top pt-3">
											<div className="d-flex mb-3 gap-3">
												<div className="avatar-container">
													<img
														src={getAvatar(res?.user?.avatar)}
														alt={item?.user?.nom}
														style={{
															width: "40px",
															height: "40px",
															borderRadius: "50%",
														}}
													/>
												</div>
												<div>
													<div className="date-response mb-3">
														<p>Envoyé le</p>
														<p className="m-0">
															{moment(res?.created_at).format(
																"DD/MM/YYYY à HH:MM"
															)}
														</p>
													</div>
													<div
														className="admin-card-description "
														dangerouslySetInnerHTML={createMarkup(
															res?.response
														)}
													/>
												</div>
											</div>
										</div>
									))
								) : (
									<AlertInfo message="Aucune réponse" />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailsMessageModal;

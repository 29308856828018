import { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "./theme";

export function useDelete<T>({
	item,
	deleteItem,
	isSuccess,
	isError,
	error,
	question,
	successMessage,
}: {
	item: T | any;
	deleteItem: (slug: string) => void;
	isSuccess: boolean;
	isError: boolean;
	error: any;
	question: string;
	successMessage: string;
}) {
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer ${question}`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				return deleteItem(item.slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${successMessage} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ?? `Une erreur de status ${err?.status} est survenue !`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}

export function useDeleteConfirme<T>({
	item,
	deleteItem,
	isSuccess,
	isError,
	error,
	question,
	successMessage,
}: {
	item: T | any;
	deleteItem: any;
	isSuccess: boolean;
	isError: boolean;
	error: any;
	question: string;
	successMessage: string;
}) {
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer ${question} `,
			icon: "question",
			input: "password",
			inputLabel: "Mettre votre mot de passe pour confirmer .",
			inputAttributes: {
				autocapitalize: "off",
			},
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			inputPlaceholder: "Mot de passe",
			preConfirm: (inputValue) => {
				console.log("dee", inputValue);
				if (inputValue) {
					let fd = new FormData();
					fd.append("password", inputValue);

					return deleteItem({ slug: item.slug, data: fd });
				} else {
					Swal.showValidationMessage(
						`le mot de passe est obligatoire pour la suppression`
					);
				}
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${successMessage} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			if (err?.status == 400) {
				Swal.fire({
					icon: "error",
					title: `Votre mot de passe est incorrecte !`,
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title:
						err?.data?.message ?? `Une erreur de status ${err?.status} est survenue !`,
					showConfirmButton: false,
					timer: 2000,
				});
			}
		}
	}, [isError]);

	return onDelete;
}

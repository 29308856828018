import React from "react";
import { BtnBack } from "../../../common/Button";
import { Link } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { createMarkup, formatMontant, getImage, isShowComma } from "../../../../utils/utils";
import DeletePepiteModal from "../../modals/user/DeletePepiteModal";
import { AiFillDelete } from "react-icons/ai";
import { IImage } from "../../../../utils/type";
import {
	useDeletePepiteImageMutation,
	usePepiteFromLocation,
} from "../../../../utils/api/pepite/pepite.api";
import { useDelete } from "../../../../utils/helpers";

const DetailPepite = () => {
	const [item] = usePepiteFromLocation();
	// console.log({ item });
	return (
		<div className="detail__pepite">
			<div className="row">
				<div className="col-md-6 col-lg-4">
					<div className="photo-container position-relative mb-3" key={item.slug}>
						<img
							src={getImage(item?.photo_principale)}
							alt="photo-principale"
							className="w-100"
						/>
					</div>
					{item?.images?.map((img) => (
						<div className="photo-container position-relative mb-3" key={item.slug}>
							<img src={getImage(img?.picture)} alt="" className="w-100" />

							<div className="photo-overlay" role="button">
								<DeleteImage item={img} />
							</div>
						</div>
					))}
				</div>
				<div className="col-md-6 col-lg-8">
					<div className="mb-4 flex-sb">
						<BtnBack label="Revenir sur mon stock" />
						{/* <button data-bs-toggle="modal" data-bs-target="#DeleteModalPepite">
							<MdDelete style={{ fontSize: 18, color: Color.red }} />
						</button> */}
					</div>
					<div className="d-flex align-items-center gap-4">
						<h4 className="pepite__title fs-31">{item?.nom}</h4>
						<div className="position-relative ps-3">
							{item?.en_ligne ? (
								<h6 className="group_info_statut online">En ligne</h6>
							) : (
								<h6 className="group_info_statut">Ébauche</h6>
							)}
						</div>
					</div>
					<div className="description__container mb-4">
						<h5 className="m-0">description de la pépite</h5>
						<div
							className="description__text"
							dangerouslySetInnerHTML={createMarkup(item?.description)}
						/>
					</div>
					<div className="fashion__card px-3 py-3 mb-5">
						<div className="row">
							<div className="group-info col-md-4">
								<h5 className="group_info_title">genre</h5>
								<p className="group_info_value">{item?.genre}</p>
							</div>
							<div className="group-info col-md-4">
								<h5 className="group_info_title">Catégorie</h5>
								<p className="group_info_value">{item?.categorie?.nom}</p>
							</div>
							<div className="group-info col-md-4">
								<h5 className="group_info_title">sous-Catégorie</h5>
								<p className="group_info_value">{item?.sous_categorie?.nom}</p>
							</div>
						</div>
						<div className="row">
							<div className="group-info col-md-4">
								<h5 className="group_info_title">Taille</h5>
								<p className="group_info_value">
									{item?.taille?.map((elem, index) => (
										<span key={item?.slug + index}>
											{elem?.nom}
											{isShowComma(item?.couleur, index)}
										</span>
									))}
								</p>
							</div>
							<div className="group-info col-md-4">
								<h5 className="group_info_title">Couleur</h5>
								<p className="group_info_value">
									{item?.couleur?.map((elem, index) => (
										<span key={item?.slug + index}>
											{elem?.nom}
											{isShowComma(item?.couleur, index)}
										</span>
									))}
								</p>
							</div>
							<div className="group-info col-md-4">
								<h5 className="group_info_title">marque</h5>
								<p className="group_info_value">{item?.marque ?? ""}</p>
							</div>
						</div>
						<div className="row">
							<div className="group-info col-md-4">
								<h5 className="group_info_title">état du vêtement</h5>
								<p className="group_info_value">{item?.etat?.nom}</p>
							</div>
							<div className="group-info col-md-4">
								<h5 className="group_info_title">Prix</h5>
								<p className="group_info_value">{formatMontant(item?.prix)}</p>
							</div>
						</div>
					</div>
					{item?.marketplace?.length > 0 && (
						<div className="group-info">
							<h5 className="group_info_title">Diffusé sur :</h5>
							<div className="flex-m gap-2">
								{item?.marketplace?.map((item) => (
									<div className="img__container" key={item?.slug}>
										<img
											src={getImage(item?.logo)}
											alt="MarketPlace"
											className="logo-brodcast"
										/>
									</div>
								))}
							</div>
						</div>
					)}
					<div className="flex-c mt-5">
						<Link
							to={`/user/mon-stock/pepites/${item?.slug}/modifier`}
							className="btn__primary fashion__btn d-block text-center text-decoration-none"
							style={{ backgroundColor: Color.plight, color: Color.dark }}
							state={item}
						>
							modifier les informations
						</Link>
					</div>
				</div>
			</div>
			<DeletePepiteModal
				message="En supprimant cette pépite, son annonce sera supprimée sur toutes
						les marketplaces où elle est diffusée ainsi que toutes ses
						informations."
				item={item}
			/>
		</div>
	);
};
export function DeleteImage({ item }: { item: IImage }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeletePepiteImageMutation();
	const onDelete = useDelete<IImage>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette image ?",
		successMessage: "Image supprimée",
	});
	return (
		<button className="btn-delete-photo" role="button" onClick={onDelete}>
			<AiFillDelete />
		</button>
	);
}

export default DetailPepite;

import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeletePepiteMutation } from "../../../../utils/api/pepite/pepite.api";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { onHide } from "../../../../utils/utils";
import { IconDeleteLarge } from "../../../shared/Icons";
import { SmallPepiteCard } from "../../user/Stock/PepiteCard";

const DeletePepiteModal = ({ message, item }: { message: string; item?: IPepite }) => {
	const [deleteItem, { isSuccess, isError, error }] = useDeletePepiteMutation();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Pépite supprimée avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				onHide("DeleteModalPepite");
				if (pathname?.includes("pepites")) {
					navigate("/user/mon-stock");
				}
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue !`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	const onDelete = () => {
		if (item) {
			deleteItem(item.slug);
		}
	};

	return (
		<div
			className="modal fade"
			id="DeleteModalPepite"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="DeleteModalPepiteLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<div className="modal__header">
							<div className="flex-c mb-3">
								<IconDeleteLarge />
							</div>
							<h2 className="modal__title">Supprimer cette pépite</h2>
						</div>
						<div className="modal__body mt-3">
							<p className="alert__info mb-2">{message}</p>
							<h3 className="modal-ask-text mb-4">
								Voulez-vous supprimer cette pépite ?
							</h3>
							<SmallPepiteCard pepite={item} />
						</div>
						<div className="modal__footer flex-end gap-5 mt-5">
							<button
								type="button"
								className="fashion__btn"
								data-bs-dismiss="modal"
								style={{ color: "#C3A083" }}
							>
								Non
							</button>
							<button
								type="button"
								className="fashion__btn btn-danger"
								style={{
									background: "#C22A29",
									borderRadius: "4px",
									color: "#FFFFFF",
									padding: "0.7rem 2rem",
								}}
								onClick={onDelete}
							>
								Supprimer
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeletePepiteModal;

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";
import {
	CollectionFormData,
	ICollection,
} from "../../../../../utils/api/collection/collection.type";
import {
	useAddOrUpdateCollectionMutation,
	useAddPepitesToCollectionMutation,
} from "../../../../../utils/api/collection/collection.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { useNavigate } from "react-router-dom";

function useCrudCollection(closeModal: () => void, item?: ICollection, setItem?: any) {
	const validationSchema = yup.object().shape({
		libelle: yup.string().required().label("libelle"),
		image: yup.mixed().label("Image"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<CollectionFormData>({
		resolver: yupResolver(validationSchema),
	});
	const userId = useAppSelector((s) => s?.user?.user?.id);

	const [imageCollection, setImageCollection] = useState<string>();

	const [addPepitesToCollection, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateCollectionMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item
					? "Collection modifiée avec succès !"
					: "Collection ajoutée avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				closeModal();
				setItem(undefined);
				setImageCollection(undefined);
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("libelle", item?.libelle);
		} else {
			setValue("libelle", "");
		}
	}, [item]);

	const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];
			console.log(file);
			setImageCollection(URL.createObjectURL(file));
			setValue("image", e.target.files[0]);
		}
	};

	const onSubmit = (data: CollectionFormData) => {
		console.log(data);
		let fd = new FormData();
		if (data?.image) {
			fd.append("image", data.image);
		}
		fd.append("libelle", data.libelle);
		fd.append("created_by", userId);
		addPepitesToCollection({ slug: item?.slug, data: fd });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		imageCollection,
		onChangeImage,
	};
}

export function useAddPepitesToCollection() {
	const navigate = useNavigate();
	const [addPepitesToCollection, { isLoading, isSuccess, error, isError }] =
		useAddPepitesToCollectionMutation();

	const [checkedCollection, setCheckedCollection] = useState<ICollection>();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Produits ajoutés  avec succès !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				onHide("ModalChoiceCollection");
				navigate(`/user/mes-collections/${checkedCollection?.slug}`);
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Une erreur inconnue a survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	const handleCheckedCollection = (item: ICollection) => {
		setCheckedCollection(item);
	};

	const onSubmit = (produitsId: number[]) => {
		console.log({ produitsId });
		addPepitesToCollection({
			collection: checkedCollection?.slug as string,
			produits: produitsId,
		});
	};

	return {
		addPepitesToCollection: onSubmit,
		isLoad: isLoading,
		handleCheckedCollection,
		checkedCollection,
	};
}
export default useCrudCollection;

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiBaseUrl } from "./http";
import defaultImage from "../assets/images/NoImage.png";
import Avatar from "../assets/images/default-avatar.png";
import { IUser } from "./api/user/user.type";
import { IMarketDiffusedPepite } from "./api/pepite/pepite.type";
import { IImage } from "./type";

export function cleannerError(errors: any, cleanner: any, timeOut: number = 3000) {
	if (errors) {
		setTimeout(() => Object.entries(errors).map(([key]: any) => cleanner(key)), timeOut);
	}
}
export function useLocationState<T>(
	defaultValue: T | any,
	redirectToWhenNull: string | null = null
): T {
	const state = useLocation().state as T;
	const navigate = useNavigate();
	useEffect(() => {
		if (!state && redirectToWhenNull) {
			navigate(redirectToWhenNull);
		}
	}, [state]);
	return state || defaultValue;
}

export const getImage = (img: string | any) => {
	if (img && !img?.includes("default.png") && !img?.includes("static/media/")) {
		if (img.indexOf("http") === 0) return img;
		return ApiBaseUrl + img;
	}
	return defaultImage;
};

export const getAvatar = (avatar: string | any) => {
	if (avatar && !avatar?.includes("default.png")) {
		if (avatar.indexOf("http") === 0) return avatar;
		return ApiBaseUrl + avatar;
	}
	return Avatar;
};

export function createMarkup(text: string | any, showDots?: boolean, numberSlice?: number) {
	return {
		__html: `${numberSlice ? text.slice(0, numberSlice) : text}${
			showDots === true ? "..." : ""
		}`,
	};
}

export function getUserPseudo(user?: IUser) {
	if (user) {
		return user?.prenom?.charAt(0) + user?.nom?.charAt(0);
	}
	return "FT";
}

export const onHide = (modalId: string) => {
	document.getElementById(modalId)?.classList.remove("show");
	$(".modal-backdrop").remove();
	$(`#${modalId}`).hide();
	$(`body`).css({ overflowY: "scroll" });
	document.getElementById("body")?.classList.remove("modal-open");
	document.getElementById("body")?.removeAttribute("style");
};

export const onOpen = (modalId: string) => {
	document.getElementsByTagName("body")[0].classList.add("modal-open");
	document.getElementById(modalId)?.classList.add("show");
	$(".modal-backdrop").append();
	$(`#${modalId}`).show();
	$(`#${modalId}`).click();
};

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
	if (additionalSearchQuery) {
		for (let key of Object.keys(additionalSearchQuery)) {
			let val = additionalSearchQuery[key];

			if (Array.isArray(val)) {
				if (val.length > 0) {
					const string = val.map((v) => `${key}=${v}`).join("&");
					if (baseUrl.includes("?")) {
						baseUrl += `&${string}`;
					} else {
						baseUrl += `?${string}`;
					}
				}
			} else if (typeof val === "boolean") {
				if (baseUrl.includes("?")) {
					baseUrl += `&${key}=${val}`;
				} else {
					baseUrl += `?${key}=${val}`;
				}
			} else {
				if (val) {
					if (baseUrl.includes("?")) {
						baseUrl += `&${key}=${val}`;
					} else {
						baseUrl += `?${key}=${val}`;
					}
				}
			}
		}
	}
	return baseUrl;
}

export function getName(item: any) {
	if (item) {
		return item?.prenom + " " + item?.nom;
	}
	return null;
}

export function useModal<T>() {
	const [isShowModal, setIsShowModal] = useState(false);
	const toggle = () => setIsShowModal((isShowModal) => !isShowModal);
	const [item, setItem] = useState<T>();
	const openModal = () => {
		setItem(undefined);
		toggle();
	};
	const openEditModal = (item: T) => {
		setItem(item);
		toggle();
	};

	return {
		isShowModal,
		toggle,
		openModal,
		openEditModal,
		closeModal: openModal,
		item,
		setItem,
	};
}

export const formatPlurial = (item: number = 0, label?: string) => {
	if (item > 1) {
		return `${item} ${label}s`;
	}
	return `${item} ${label}`;
};

export const formatMontant = (num?: number | string) => {
	return formatAmount(num);
};

export function formatAmount(amount?: number | string) {
	if (amount || amount === 0)
		return new Intl.NumberFormat("fr-FR", {
			style: "currency",
			currency: "EUR",
			maximumFractionDigits: 2,
			minimumFractionDigits: 0,
		}).format(parseFloat(String(amount)) || 0);

	return "-";
}

export function formatMessageStatus(status: string) {
	return status === "new" ? "Nouveau" : status === "traited" ? "Traité" : "En cours";
}

export function formatMessageStatusClass(status: string) {
	return status === "new" ? "bg-status" : status === "traited" ? "bg-success" : "bg-secondary";
}
export function isShowComma(item: any, index: number) {
	return item.length - 1 > index ? ", " : null;
}

export const calculatePageCount = (total?: number, perPage: number = 10) => {
	if (total) {
		return Math.ceil(total / perPage);
	}
	return 0;
};

export const convertDimensionsUnitForEbay = (unity?: string) => {
	if (unity) {
		if (unity === "m") {
			return "METER";
		} else if (unity === "cm") {
			return "CENTIMETER";
		} else if (unity === "feet") {
			return "FEET";
		} else if (unity === "inch") {
			return "INCH";
		}
	}
	return "";
};

export const convertWeightUnitForEbay = (unity?: string) => {
	if (unity) {
		if (unity === "kg") {
			return "KILOGRAM";
		} else if (unity === "g") {
			return "GRAM";
		} else if (unity === "oz") {
			return "OUNCE";
		} else if (unity === "lb") {
			return "POUND";
		}
	}
	return "";
};
export function useItem<T>() {
	const [item, setItem] = useState<T>();
	const getItem = (item: T) => {
		setItem(item);
	};
	return {
		item,
		getItem,
	};
}

export function checkIfProductIsBroadcastAllMarket(diffusions?: IMarketDiffusedPepite[]) {
	if (diffusions) {
		const market = diffusions?.map((item) => item?.marketplace);
		if (market?.includes("ebay") && market?.includes("etsy")) {
			return true;
		} else {
			return false;
		}
	}
	return undefined;
}
export function getArrayImagesLength(array?: (File | undefined)[] | IImage[]) {
	if (array) {
		return array?.length;
	}
	return 0;
}

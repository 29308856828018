import React from "react";
import { Link } from "react-router-dom";
import { IconBook } from "../shared/Icons";

function Popup() {
	return (
		<div className="fas__popup__container p-2">
			<h3 className="popup__title">Besoin d’aide ?</h3>

			<p className="popup__message mt-2">Consulter notre guide pratique !</p>
			<Link to="/faq" className="popup__btn flex-c gap-3 mt-4 mb-3">
				<IconBook />
				<span>Consulter</span>
			</Link>
			<button
				title="Cliquer sur le bouton pour nous contacter"
				className="popup__btn"
				data-bs-toggle="modal"
				data-bs-target="#ContactModal"
			>
				Nous Contacter
			</button>
		</div>
	);
}

export default Popup;

import React from "react";
import { ApiBaseUrl, Env } from "../../../../utils/http";
import packageJson from "../../../../../package.json";

const Config = () => {
	return (
		<div>
			<div className="flex-m gap-3">
				<h5>Environnement: </h5>
				<h6 className="text-capitalize">{Env}</h6>
			</div>
			<div className="flex-m gap-3">
				<h5>Version: </h5>
				<h6 className="text-capitalize">{packageJson.version}</h6>
			</div>
			<div className="flex-m gap-3">
				<h5>Api Url: </h5>
				<h6>{ApiBaseUrl}</h6>
			</div>
		</div>
	);
};

export default Config;
